import React, { useState, forwardRef, useContext, useEffect, useCallback } from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import DatePicker from "react-datepicker";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  ProjectCard,
  PreviewAltCard,
  UserAvatar,
  Col,
} from "../components/Component";

import { FormGroup, Progress, Label, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { BookingContent } from "../rcomponents/bookingContext/BookingContext";
import axios from "axios";
import { animateScroll as scroll } from 'react-scroll';

const Homepage = () => {
  // create booking context
  const {
    setghOccupency,
    addToDashboard,
    dashboard,
    formatDateddmmyy,
    // User_Details,
    // getGhName,
    token,
    proxy_Url,
    gridoccupencydatafunc,
    setSchedulerData,
    // setSchedulerData,
    // setSchedulerData_original_func,
    // setOccupencyData,
    getPropertyDetails,
    getsetentityname,
    gridstartenddate,
    isLoadingfunc,
    getPropertyId,
    ghaccessdatafunc,
    ghaccessdata,
    getGHClientName,
    getandsetGHName,
    getnewbookingdata,
  } = useContext(BookingContent);
  const [dashDetails, EntityName, EntityDetails] = dashboard.flat();
  // Set Month for datepicker
  const [month, setMonth] = useState(new Date());

  const [isLoading, setIsLoading] = useState(false);
  //set session id
  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  sessionStorage.setItem("access_Token1", localStorage.getItem("accessToken1"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;
  const history = useHistory();

  //add data to dashboard

  useEffect(() => {

if(!ghuserId) {
  history.push("/auth-login");
 

}

//
    let date = formatDateddmmyy(month)?.split("/");

    let bodyData = { MonthDate: "01/" + date[1] + "/" + date[2], UserId: ghuserId };
    const headers = {
      "Content-Type": "application/json",
      "x-access-token": `${token}`,
    };
    axios
      .post(`${proxy_Url}/dashboard`, bodyData, { headers: headers })
      .then(({ data }) => {
        addToDashboard(data.Result);
        setIsLoading(true);
      })
      .catch((error) => 
    {
      // console.log(error.message)
  }
      );
    ghaccessdatafunc();

    // remove user credentials
    if (sessionStorage.getItem("access_Token1")) {
      localStorage.setItem("accessToken1", JSON.stringify(JSON.parse(sessionStorage.getItem("access_Token1"))));
      localStorage.setItem("accessToken", JSON.stringify(JSON.parse(sessionStorage.getItem("credentials"))));
    } else {
      localStorage.setItem("accessToken1", "");
      localStorage.setItem("accessToken", "");
      history.push("/auth-login");
    }
  }, [month, ghuserId]);



  const getPropertynamefunc = useCallback((value) => {
    console.log(value)
    getGHClientName([]);
    setSchedulerData({});
    let inputmonth_add = "01/" + document.querySelector(".form-control.date-picker").value.replace(",", "/");
    let inputmonth = inputmonth_add.split("/");
    let selected_date = inputmonth[1] + "/" + inputmonth[0] + "/" + inputmonth[2];
    let date = new Date(selected_date);
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1);
    let splitfromdate = new Date(firstDay).toISOString().split("T")[0].split("-");
    let todatesplit = new Date(lastDay).toISOString().split("T")[0].split("-");

    let fromdate = splitfromdate[1] + "/" + splitfromdate[2] + "/" + splitfromdate[0];
    let todate = todatesplit[1] + "/" + todatesplit[2] + "/" + todatesplit[0];

    //oney for start end date for grid

    let newday = new Date();
    let fristday = new Date(newday.setDate(1));
    let endday = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
    let end_daynew = new Date(new Date().setDate(endday));

    gridstartenddate({ FromDte: fristday, ToDte: end_daynew });

    //oney for start end date for grid
    isLoadingfunc(true);

    let gridData = {
      PropertyId: `${value?.PropertyId}`,
      FromDte: fromdate,
      ToDte: todate,
      PropertyName: value?.PropertyName,
    };

    getPropertyId(value?.PropertyId);
    getnewbookingdata("",value?.PropertyId);

    //let gridfromdate = splitfromdate[2] + "/" + splitfromdate[1] + "/" + splitfromdate[0];
    //let gridtodate = todatesplit[2] + "/" + todatesplit[1] + "/" + todatesplit[0];
    let gridgridData = {
      PropertyId: `${value?.PropertyId}`,
      FromDte: fristday,
      ToDte: end_daynew,
      PropertyName: value?.PropertyName,
    };
     gridstartenddate(gridgridData);

     gridoccupencydatafunc(gridData);
    let val = {
      Property: value?.PropertyName,
      Id: value?.PropertyId,
      CityName: value?.CityName,
      StateName: "",
    };
  
    
//get property name id
    getandsetGHName(val);
    getPropertyDetails(val);
   // getandsetGHName(propertyselect[0]);
    let clientdate = {
      PropertyId: value?.PropertyId, //propertuId from Property selection
      UserId: ghuserId,
    };
    getsetentityname(clientdate);
    setghOccupency(false);

    history.push("/reservation");
  }, []);
  //create datepicker to dashboard
  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <div onClick={onClick} ref={ref}>
      <div className="form-icon form-icon-left">
        <Icon name="calendar"></Icon>
      </div>
      <input className="form-control date-picker" type="text" value={value} onChange={onChange} />
    </div>
  ));

  const pageup = () => {
    scroll.scrollToTop({
      duration: 1000,  // Duration of the scroll animation in milliseconds
      smooth: 'easeInOutQuint'  // Easing function for the scroll animation
    });

   };

 

  return (
    <React.Fragment>



      <Head title="Guest house booking"></Head>
      {!isLoading ? (
        <div className="spinner-class">
          <Spinner size="sm" />
          <span> Loading... </span>
        </div>
      ) : (
        <Content>
           
          <BlockHead size="sm">
          
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page className="dash_title">
                  {" "}
                  Dashboard
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <FormGroup className="d-flex">
                  <Label className="mb-0">Select Month </Label>
                  <div className="form-control-wrap mt-1 ml-1">
                    <DatePicker
                      selected={month}
                      onChange={(date) => {
                        setMonth(date);
                        setIsLoading(false);
                      }}
                      id="date-month"
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      showFullMonthYearPicker
                      className="form-control date-picker"
                      customInput={<ExampleCustomInput />}
                      componentsProps={{
                        actionBar: { actions: ["clear", "today", "cancel", "accept"] },
                      }}
                    />
                  </div>
                </FormGroup>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            {/*dashboard details  */}
            {dashDetails &&
              dashDetails.map((item, idx) => {
                return (
                  <Row className="g-gs project-head-group homepage" key={idx}>
                    <Col sm="6" lg="3" xxl="3">
                      <ProjectCard>
                        <div className="project-head">
                          <UserAvatar className="sq" theme={item.avatarClass} text="TRN" />
                          <div className="project-info">
                            <span className="sub-text fw-bold">Total Room Night's</span>
                            <h6 className="title">{item.TotalRoomCount}</h6>
                          </div>
                        </div>
                        <div className="project-progress">
                          <div className="project-progress-details">
                            <div className="project-progress-task">
                              {/* <Icon name="check-round-cut"></Icon> 
                              <span>Total Rooms</span>*/}
                            </div>
                            <div className="project-progress-percent">{item.TotalRoomCountPer}%</div>
                          </div>
                          <Progress
                            className="progress-pill progress-md bg-light total-Rooms"
                            value={item.TotalRoomCountPer}
                          ></Progress>
                        </div>
                      </ProjectCard>
                    </Col>
                    <Col sm="6" lg="3" xxl="3">
                      <ProjectCard>
                        <div className="project-head">
                          <UserAvatar className="sq bg-teal" theme="pink" text="ORN" />
                          <div className="project-info">
                            <span className="sub-text fw-bold">Occupied Room Night's</span>
                            <h6 className="title">{item.OccupiedRoomCount}</h6>
                          </div>
                        </div>
                        <div className="project-progress">
                          <div className="project-progress-details">
                            <div className="project-progress-task">
                              {/* <Icon name="check-round-cut"></Icon>
                              <span>Occupied Rooms</span> */}
                            </div>
                            <div className="project-progress-percent">{item.OccupiedRoomCountPer}%</div>
                          </div>
                          <Progress
                            className="progress-pill progress-md bg-light Occupied-Rooms"
                            value={item.OccupiedRoomCountPer}
                          ></Progress>
                        </div>
                      </ProjectCard>
                    </Col>
                    <Col sm="6" lg="3" xxl="3">
                      <ProjectCard>
                        <div className="project-head">
                          <UserAvatar className="sq" theme="info" text="ARN" />
                          <div className="project-info">
                            <span className="sub-text fw-bold">Available Room Night's</span>
                            <h6 className="title">{item.AvailableRoomCount}</h6>
                          </div>
                        </div>
                        <div className="project-progress">
                          <div className="project-progress-details">
                            <div className="project-progress-task">
                              {/* <Icon name="check-round-cut"></Icon>
                              <span>Available Rooms</span> */}
                            </div>
                            <div className="project-progress-percent">{item.AvailableRoomCountPer}%</div>
                          </div>
                          <Progress
                            className="progress-pill progress-md bg-light Available-Rooms"
                            value={item.AvailableRoomCountPer}
                          ></Progress>
                        </div>
                      </ProjectCard>
                    </Col>
                    <Col sm="6" lg="3" xxl="3">
                      <ProjectCard>
                        <div className="project-head">
                          <UserAvatar className="sq" theme="warning" text="BRN" />
                          <div className="project-info">
                            <span className="sub-text fw-bold">Blocked Room Night's</span>
                            <h6 className="title">{item.BlockedRoomCount}</h6>
                          </div>
                        </div>
                        <div className="project-progress">
                          <div className="project-progress-details">
                            <div className="project-progress-task">
                              {/* <Icon name="check-round-cut"></Icon>
                              <span>Blocked Rooms</span> */}
                            </div>
                            <div className="project-progress-percent">{item.BlockedRoomCountPer}%</div>
                          </div>
                          <Progress
                            className="progress-pill progress-md bg-light Blocked-Rooms"
                            value={item.BlockedRoomCountPer}
                          ></Progress>
                        </div>
                      </ProjectCard>
                    </Col>
                   
                  </Row>
                );
              })}
          </Block>
          {/*Entity details  */}
          {EntityName && EntityName.length > 1
            ? EntityName.map((item, index) =>
                typeof item.CityName === "string" ? (
                  <Row className="g-gs homepage" key={index}>
                    <Col md="12">
                      <BlockTitle page key={index}>
                        {item.CityName}
                      </BlockTitle>
                    </Col>{" "}
                    {EntityDetails.filter((entitem) => (entitem.CityName === item.CityName ? entitem : null)).map(
                      (entfilteritem, index) => (
                        <Col md="3" key={index}>
                          
                          <PreviewAltCard className="h-100 dashboard">
                            
                            <div className="rating-card d-flex flex-column b_bottom">
                              <div className="user-info mb-2">
                                <h6 className="entitycard_title">{entfilteritem.PropertyName}</h6>
                              </div>
                              <div className="progress-rating justify-content-between w-100">
                                <div className="progress-rating mr-2 small">
                                  <small>Room Night's :&nbsp;</small>
                                  <b> {entfilteritem.TotalRoomCount}</b>
                                </div>
                                <div className="progress-rating mr-2 view-details">
                                  {/* <Link
                                    to={{
                                      pathname: `${process.env.PUBLIC_URL}/reservation`,
                                      query: { title: entfilteritem.PropertyName },
                                    }}
                                  >  */}
                                  <small onClick={() => getPropertynamefunc(entfilteritem)}>View Details</small>
                                  {/* </Link>  */}
                                </div>
                              </div>
                            </div>

                            <div className="gy-3 pt-4">
                              <div className="progress-rating">
                                <div className="progress-rating-title occupied">{entfilteritem.OccupiedRoomCount}</div>
                                <div className="w-75">
                                  <div className="progress-rating-title w-100 center occupied">Occupied</div>
                                  <Progress
                                    value={entfilteritem.OccupiedRoomCountPer}
                                    color="teal"
                                    className="progress-md"
                                  ></Progress>
                                </div>
                                <div className="progress-rating-percent occupied">
                                  {entfilteritem.OccupiedRoomCountPer}%{" "}
                                </div>
                              </div>
                              <div className="progress-rating">
                                <div className="progress-rating-title available">
                                  {entfilteritem.AvailableRoomCount}
                                </div>
                                <div className="w-75">
                                  <div className="progress-rating-title w-100 center available">Available</div>
                                  <Progress
                                    value={entfilteritem.AvailableRoomCountPer}
                                    color="info"
                                    className="progress-md"
                                  ></Progress>
                                </div>
                                <div className="progress-rating-percent available">
                                  {entfilteritem.AvailableRoomCountPer}%{" "}
                                </div>
                              </div>
                              <div className="progress-rating">
                                <div className="progress-rating-title blocked">{entfilteritem.BlockedRoomCount}</div>
                                <div className="w-75">
                                  <div className="progress-rating-title w-100 center blocked">Blocked</div>
                                  <Progress
                                    value={entfilteritem.BlockedRoomCountPer}
                                    color="warning"
                                    className="progress-md"
                                  ></Progress>
                                </div>
                                <div className="progress-rating-percent blocked">
                                  {entfilteritem.BlockedRoomCountPer}%{" "}
                                </div>
                              </div>
                              
                            </div>
                            
                          </PreviewAltCard>
                         
                        </Col>
                      )
                    )}
                     
                  </Row>
                ) : null
              )
            : null}
            <Row className={'pageup'}><Col><span onClick={pageup}> Back to top</span></Col></Row>
        </Content>
      )}
    </React.Fragment>
  );
};
export default Homepage;
