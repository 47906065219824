import React, { useEffect, useContext, useState, useRef, useCallback } from "react";
import "../../assets/scss/changeEditor.scss";
import { BookingContent } from "../bookingContext/BookingContext";
import axios from "axios";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import CustomFieldsEdit from "../editorTableComponent/CustomFieldsEdit";
import { setTime } from "@syncfusion/ej2-react-schedule";
import moment from "moment";

const BlockModifyData = ({ ghnamechange }) => {
  const {
    setSchedulerData,
    scheduledataoriginal,
    gridoccupencydatafunc,
    property_details,
    setCancelEvent,
    token,
    show_error_templatefunc,
    getErrorMessagefunc,
    errorClassNamefunc,
    isLoadingfunc,
    proxy_Url,
    change_event_data,
    editmodificationfunc,
    editmodificationdata,
    staychangedate,
    changeeventdatafunc,
    editdatafunc,
    ghaccessdata,
    set_Error_Message_func,
    propertyID,
    setErrorMsgClassfunc,
    client_id,
    clientID,
    setstaychangedate,
    clientcustomfieldscollection,
    edit_cus_data,
    propInput,
    stayshift,
    GH_property_ClientName,
    stayshiftfunc,
    roomshiftdata,
    changeroomid,
    blockmodify,
    getmodifyblockdata,
  } = useContext(BookingContent);

  //set session id

  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  sessionStorage.setItem("access_Token1", localStorage.getItem("accessToken1"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;
  let remarks = useRef();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [e_customFields, setE_customFields] = useState(false);
  const [getErrorMessage, setgetErrorMessagefunc] = useState([]);
  const [get_ErrorMessage, setget_ErrorMessagefunc] = useState([]);
  const [err_msg, seterr_msg] = useState("");
  const [edit_modi_check, setedit_modi_check] = useState([]);
  const [changelog, setchangelog] = useState([]);
  const [showchangelog, setshowchangelog] = useState(false);
  const [msg_option, setmsg_option] = useState([]);
  const [bedstay, setbedstay] = useState("");
  const [bedidavailablecheckcount, setbedidavailablecheckcount] = useState([]);
  const [editmodificationfilterdata, seteditmodificationfilterdata] = useState([]);
  const [end_Date, setend_Date] = useState(null);
  const [mindate, setmindate] = useState(null);
  const [maxdate, setmaxdate] = useState(null);

  let ghproperty_Name = document.querySelector("#ghproperty");
  let ghproperty_Entity = document.querySelector("#Entity_select");
  let endmodifyObj = useRef();
  let startmodifyObj = useRef();
  let editmodicheck;
  let mincheckindate;

  //get bet count

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };


let mndate = blockmodify[0]?.FromDt?.split("/");
let mdate = `${mndate[1]},${mndate[0]},${mndate[2]}`;
let endate = blockmodify[0]?.ToDt?.split("/");

  useEffect(() => {
setmindate(new Date(new Date(mdate).setDate(new Date(mdate).getDate()+1)));
setmaxdate(new Date(`${endate[1]},${endate[0]},${endate[2]}`))


  },[])
  //start date focus
  const startonFocusmodify = (args) => {
    startmodifyObj.show();
    document.querySelector("#edit_s_date").blur();
  };
  //End date focus
  const EndonFocusmodify = (args) => {
    endmodifyObj.show();

    document.querySelector("#edit_e_date").blur();
  };
  const closeModify = (args) => {
    getmodifyblockdata([]);
  };
  const onSubmit = (args) => {};
  const saveModify = (args) => {
    isLoadingfunc(true);
   
    let comment = document.querySelector("#ClientRequestNo")?.value;
    if (comment !== "") {
      let blockcheckData = {
        RoomId: blockmodify[0]?.RoomId,
        FromDt: blockmodify[0]?.FromDt,
        ToDt: document.querySelector("#edit_s_date")?.value,
        FromTime: blockmodify[0]?.FromTime,
        ToTime: blockmodify[0]?.ToTime,
        CheckIn24Hrs: blockmodify[0]?.CheckIn_24HrsFlg === false ? 0 : 1,
        UserId: ghuserId,
        Comments: document.querySelector("#ClientRequestNo")?.value,
        PropertyId: blockmodify[0]?.PropertyId,
        Id: blockmodify[0]?.Id,
      };
      
      axios
        .post(`${proxy_Url}/Blockupdate`, blockcheckData, { headers: headers })
        .then(({ data }) => {
         
          closeModify([]);
          ghnamechange();
          isLoadingfunc(false);
        })
        .catch((error) => {});
    } else {
      setgetErrorMessagefunc(["Please enter modification comments"]);
      isLoadingfunc(false);
      setTimeout(() => {
        setgetErrorMessagefunc([]);
      }, 2000);
    }
  };

  return (
    <>
      {getErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span>{err_msg} Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
       </div> */}
            </div>
            <div className="error_content t-center">
              {getErrorMessage?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}

      <form onSubmit={onSubmit}>
        <div className="changeEditor">
          <div className="editorBody">
            <div className="header">
              <span>
                Block Modification <span>Room No</span> : <b>{blockmodify[0]?.RoomNo}</b>{" "}
              </span>
              <span className="d-flex ml-auto bg-light popup_Close" onClick={closeModify}>
                X
              </span>
            </div>
            <div className="body">
              <div className="row">
                <div className="col-md-12 col-lg-6 col-xl-4 booking_input">
                  <div className="col-md-12">
                    <span>From Date</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :
                    {blockmodify[0]?.FromDt + "  " + blockmodify[0]?.FromTime}
                    {/* <b>{change_event_data?.Type}</b> */}
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 booking_input">
                  <div className={`form-group col-md-12 blockdate`} style={{ border: "0px" }}>
                    <label htmlFor="Check_In_Date">To Date :</label>{" "}
                    <DatePickerComponent
                      format="dd/MM/yyyy"
                      id="edit_s_date"
                      selected={startDate}
                      min={mindate}
                      ref={(date) => (startmodifyObj = date)}
                      className="form-control date-picker "
                      onChange={(date) => {}}
                      value={blockmodify[0]?.ToDt}
                      max={maxdate}
                      onFocus={startonFocusmodify}
                    />{" "}
                    {blockmodify[0]?.ToTime}
                  </div>
                </div>
                <div className="col-md-12  my-2" style={{ border: "0px" }}>
                  Comments :{" "}
                  <input
                    type="text"
                    name="ClientRequest"
                    defaultValue={blockmodify[0]?.Comments}
                    className="form-control hide_emp"
                    id="ClientRequestNo"
                    //  onChange={handelChange}
                    //  onInput={propInput}
                  />
                </div>
              </div>

              <div className="w-100 p-1 mt-3" style={{ background: "#dde9f8" }}>
                <div className="">
                  <div className="row"></div>
                </div>
              </div>
            </div>
            <div className="footer">
              <input type="button" value="Save" className="btn btn-success btn-sm mr-1" onClick={saveModify} />
              {/* <input type="button" value="Clear" className="btn btn-danger btn-sm" onClick={clearData} /> */}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default React.memo(BlockModifyData);
