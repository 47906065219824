import React, { useContext, useCallback, useState, useRef, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { DatePickerComponent, TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { BookingContent } from "../bookingContext/BookingContext";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import axios from "axios";
import { Checkbox, CheckboxGroup } from "rsuite";
import BlockModifyData from "../eventChange/BlockModifyData";
import BlockHistory from "../eventChange/BlockHistory";
import SpinnerTemplate from "../error_component/Spinner";

const RoomBlock = () => {
  const {
    ghName,
    proxy_Url,
    getGhName,
    token,
    show_error_templatefunc,
    showerror_template,
    getErrorMessagefunc,
    errorClassNamefunc,
    isLoadingfunc,
    is_loading,
    propertyloadfunc,
    propInput,
    set_Error_Message_func,
    setErrorMsgClassfunc,
    getmodifyblockdata,
    blockmodify,
    blockhistory,
    getmodifyhistorydata,
    GH_property_ClientName,
  } = useContext(BookingContent);
  //set session id
  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };
  const [formValues, setFormValues] = useState([]);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [time_change, settime_changefunc] = useState("");
  const [timediff, settimediff] = useState(5);
  const [guesterrormsg, setguesterrormsg] = useState([]);
  const [getErrorMessage, setgetErrorMessage] = useState([]);
  const [bookinggetErrorMessage, setbookinggetErrorMessage] = useState([]);
  const [bookinggetsuccessErrorMessage, setbookinggetsuccessErrorMessage] = useState([]);
  const [set_modify, setModify] = useState([]);
  const [set_history, setHistory] = useState([]);
  const [errormsg, seterrormsg] = useState([]);
  let [selected, setSelected] = React.useState([]);
  let [toblockdata, settoblockdata] = React.useState([]);
  let [selectedall, selectedallfunc] = React.useState([]);
  let [intime_change, setintime_change] = React.useState("");
  let [outtime_change, setouttime_change] = React.useState("11:59 AM");
  let [mindate, setmindate] = React.useState(null);
  let [maxdate, setmaxdate] = React.useState(null);
  let [groupdata, setGroupdata] = React.useState([]);
  let [blockedRooms, setblockedRooms] = React.useState([]);
  let [roomdataDetails, setRoomdataDetails] = React.useState([]);

  let startObj = useRef();
  let endObj = useRef();
  let propertyloadref = useRef();
  let bookingStatusref = useRef();
  let roomnoref = useRef();
  let roomtyperef = useRef();
  let intimeobj = useRef();
  let outtime = useRef();
  //console.log(toblockdata)
  useEffect(() => {
    propertyloadfunc();
    let todate = new Date(
      new Date(formValues.blockfromDate || new Date()).setDate(
        new Date(formValues.blockfromDate || new Date()).getDate() + 1
      )
    );
    setFormValues((pre) => ({ ...pre, blocktoDate: todate }));
    selectedallfunc([]);

    setmindate(new Date(new Date().setDate(new Date().getDate() + 1)));
  }, []);

  useEffect(() => {
    const newData = roomdataDetails.map((obj1) => {
      const matchedObj = selected.find((obj2) => obj1.RoomNoId === obj2);
      if (matchedObj) {
        return obj1;
      }
      return matchedObj;
    });
    let filteredroom = newData.filter((item) => item !== undefined);
    setGroupdata(filteredroom);
    if (roomdataDetails?.length > 0 && roomdataDetails?.length === filteredroom?.length) {
      selectedallfunc(["Select All"]);
    } else {
      selectedallfunc([]);
    }

    if (selected?.length > 10) {
      setgetErrorMessage(["Maximum 10 rooms allowed for room block"]);
      setTimeout(() => {
        //console.log("5")
        setgetErrorMessage([]);
      }, 2000);
    }
  }, [selected]);

  const onSubmit = useCallback((e) => {
    // console.log("bodyData");
    seterrormsg([]);
    let guesthousename = document.querySelector("#booking_ghname")?.value;

    let comments = document.querySelector("#blockroom")?.value;
    let getroomno;
    let roomcount = selected?.length;
    let property_id = ghName[0]?.filter((item) => item.Property?.trim() === guesthousename?.trim());
    //console.log(property_id?.length);

    if (property_id?.length > 0 && comments !== "" && selected?.length > 0 && selected?.length <= 10) {
      isLoadingfunc(true);
      getroomno = groupdata.map((item) => item?.RoomId);
      let propertcheckindetails = blockedRooms.filter((item) => (item.PropertyId == property_id[0]?.Id ? item : null));

      for (let k = 0; k < getroomno?.length; k++) {
        let blockcheckData = {
          RoomId: getroomno[k],
          FromDt: document.querySelector("#blockfromDate")?.value,
          ToDt: document.querySelector("#blocktoDate")?.value,
          FromTime: document.querySelector("#blockintime")?.value,
          ToTime: document.querySelector("#blockouttime")?.value,
          CheckIn24Hrs: propertcheckindetails[0]?.CheckIn_24HrsFlg,
          UserId: ghuserId,
        };

        setbookinggetErrorMessage([]);
        axios
          .post(`${proxy_Url}/BlockCheckDetails`, blockcheckData, { headers: headers })
          .then(({ data }) => {
            isLoadingfunc(true);
            if (data?.Result === "No records found") {
              if (guesthousename !== "" && comments !== "" && parseInt(roomcount) > 0) {
                let s_dates = document.querySelector("#blockfromDate")?.value.split("/");
                let e_dates = document.querySelector("#blockouttime")?.value.split("/");
                let bodyDatainsert = {
                  RoomId: getroomno[k],
                  FromDt: s_dates[0] + "/" + s_dates[1] + "/" + s_dates[2],
                  ToDt: e_dates[0] + "/" + e_dates[1] + "/" + e_dates[2],
                  FromTime: document.querySelector("#blockintime")?.value,
                  ToTime: document.querySelector("#blockouttime")?.value,
                  CheckIn24Hrs: propertcheckindetails[0]?.CheckIn_24HrsFlg || 0,
                  UserId: ghuserId,
                  PropertyId: property_id[0]?.Id,
                  Comments: document.querySelector("#blockroom")?.value,
                };

                axios
                  .post(`${proxy_Url}/BlockInsertDetails`, bodyDatainsert, { headers: headers })
                  .then(({ data }) => {
                    //  seterrormsg("Success")
                    // setgetErrorMessage(["Room Blocked Successfully" ]);
                    document.querySelector("#booking_ghname").value = "";
                    setbookinggetsuccessErrorMessage(["Room Blocked successfully"]);

                    isLoadingfunc(false);

                    setTimeout(() => {
                      // console.log("1")
                      setbookinggetsuccessErrorMessage([]);
                    }, 2000);

                    if (getroomno.length == k + 1) {
                      setRoomdataDetails([]);
                      setblockedRooms([]);
                      setTimeout(() => {
                        document.querySelector("#blockroom").value = "";
                      }, 2000);
                    }
                  })
                  .catch((error) => {
                    // console.log(error.message);
                  });
              } else {
                seterrormsg([]);
                isLoadingfunc(false);
                if (guesthousename === "") {
                  seterrormsg(["Select guest house name from the list"]);

                  setTimeout(() => {
                    // console.log("1")
                    seterrormsg([]);
                  }, 2000);
                } else {
                  // seterrormsg([]);
                  if (guesthousename === "") {
                    seterrormsg(["Select guest house name from the list"]);
                  }
                  if (comments === "") {
                    seterrormsg((pre) => [pre, "Comment is required"]);
                  }

                  if (parseInt(roomcount) === 0) {
                    seterrormsg((pre) => [pre, "Minimum 1 room name is required for block"]);
                  }
                  if (parseInt(selected?.length) > 10) {
                    seterrormsg((pre) => [pre, "Maximum 10 rooms allowed for block"]);
                  }
                  // console.log("first")
                }
                setTimeout(() => {
                  //  console.log("2")
                  //  seterrormsg([]);
                }, 2000);
              }
            } else {
              isLoadingfunc(false);
              //setgetErrorMessage([`Booking Available on the specific period-${data.Result[0][0]?.BookingCode},`]);
              setbookinggetErrorMessage([
                `Booking Available on the specific period-${data.Result[0][0]?.BookingCode},`,
              ]);
              setTimeout(() => {
                //  console.log("3")
                setgetErrorMessage([]);
                setbookinggetErrorMessage([]);
              }, parseInt(getroomno?.length) * 2500);
            }
          })
          .catch((error) => {
            //  console.log(error.message);
          });
      }
      setTimeout(() => {
        // console.log("4")
        // console.log(getroomno?.length)
        setgetErrorMessage([]);
      }, 2000);
      selectedallfunc([]);
      setSelected([]);
      //ghnamechange({value:document.querySelector("#booking_ghname")?.value})
      // clearData();
    } else {
      setgetErrorMessage([]);
      if (property_id?.length === 0) {
        setgetErrorMessage(["Please select property name from the list"]);
      }
      if (comments === "") {
        setgetErrorMessage((pre) => [...pre, "Please enter comments"]);
      }
      if (selected?.length === 0) {
        setgetErrorMessage((pre) => [...pre, "Please select minimum 1 room for block"]);
      }
      if (selected?.length > 10) {
        setgetErrorMessage((pre) => [pre, "Maximum 10 rooms allowed for room block"]);
      }

      setTimeout(() => {
        // console.log("5")
        setbookinggetErrorMessage([]);
        setgetErrorMessage([]);
      }, 2000);
    }
  });

  const ghnamechange = (e) => {
    //console.log("first")
    setRoomdataDetails([]);
    setblockedRooms([]);
    setSelected([]);
    let property_id = ghName[0]?.filter(
      (item) =>
        item.Property?.toUpperCase().trim() === document.querySelector("#booking_ghname")?.value.toUpperCase().trim()
    );

    if (property_id?.length > 0) {
      setRoomdataDetails([]);
      setblockedRooms([]);
      setSelected([]);
      let bodyData = {
        PropertyId: property_id[0]?.Id,
        RoomType: "Normal",
      };

      isLoadingfunc(true);
      axios
        .post(`${proxy_Url}/roomnoload`, bodyData, { headers: headers })
        .then(({ data }) => {
          let room_type = data?.Result[0]?.map((item) => item.RoomType);
          //setRoomType(room_type);

          setRoomdataDetails(data?.Result[0]);
          isLoadingfunc(false);
          //console.log(data?.Result[0]?.length)
          //   let blockroomid = data?.Result[0]?.map(item => item?.RoomId);

          //  console.log(blockroomid)
          //  for(let i = 0;i<blockroomid?.length; i++){
          //   // data?.Result[0].map(item =>{
          //   let blockcheckData = {
          //     "RoomId":blockroomid[i],
          //     "FromDt":document.querySelector("#blockfromDate")?.value,
          //     "ToDt":document.querySelector("#blocktoDate")?.value,
          //     "FromTime":document.querySelector("#blockintime")?.value,
          //     "ToTime":document.querySelector("#blockouttime")?.value,
          //     "CheckIn24Hrs":0,
          //     "UserId":ghuserId
          //    }
          //    console.log(blockcheckData)
          //   axios.post(`${proxy_Url}/BlockCheckDetails`, blockcheckData, { headers: headers })
          //   .then(({ data }) => {
          //     console.log(data)
          //     if(data?.Result === 'No records found') {
          //       // setRoomdataDetails(pre => [...pre,item]);
          //     }
          //   }).catch(error => {})
          // }
          //  })
        })
        .catch((error) => {});

      // blocked data details
      let blockedbodyData = {
        PropertyId: property_id[0]?.Id,
        UserId: ghuserId,
        CheckIn24Hrs: 1,
      };

      axios
        .post(`${proxy_Url}/BlockPropertyDataDetails`, blockedbodyData, { headers: headers })
        .then(({ data }) => {
          let room_type = data?.Result[0]?.map((item) => item.RoomType);
          //setRoomType(room_type);

          //propertyfilterfunc();
          setblockedRooms(data?.Result[0]);
          isLoadingfunc(false);
        })
        .catch((error) => {});
    }
  };

  const modify = (val) => {
    getmodifyblockdata([val]);
    setModify([val]);
  };
  const history = (val) => {
    getmodifyhistorydata([val]);
    setHistory([val]);
  };

  const unblock = (val) => {
    isLoadingfunc(true);

    let property_id = ghName[0]?.filter(
      (item) => item.Property?.trim() === document.querySelector("#booking_ghname")?.value?.trim()
    );
    let deleteData = {
      PropertyId: `${val?.Id}`,
      RoomId: `${val?.RoomId}`,
      CheckIn24Hrs: val?.CheckIn_24HrsFlg === true ? 1 : 0,
      UserId: ghuserId,
    };

    // delete data

    axios
      .post(`${proxy_Url}/BlockDeleteroomsDataDetails`, deleteData, { headers: headers })
      .then((res) => {
        setbookinggetsuccessErrorMessage(["Room unblocked successfully"]);
        setTimeout(() => {
          setbookinggetsuccessErrorMessage([]);
        }, 1000);
        setErrorMsgClassfunc({ class: "error-success", msg: "Success" });
        // console.log(res);
        isLoadingfunc(false);

        ghnamechange();
      })
      .catch((error) => {
        isLoadingfunc(false);
      });
  };
  const HandleoutChange = (event) => {
    ghnamechange();
  };
  const HandleChange = (event) => {
    let todate = new Date(new Date(event.value).setDate(new Date(event.value).getDate() + 1));
    setFormValues((pre) => ({ ...pre, blockfromDate: event.value, blocktoDate: todate }));
    ghnamechange();
    setTimeout(() => {
      setmindate(new Date(new Date(event.value).setDate(new Date(event.value).getDate() + 1)));
    }, 1000);
  };
  const onFocussdate = () => {
    startObj.show();
    document.querySelector("#blockfromDate").blur();
  };
  const onFocusstodate = () => {
    endObj.show();
    document.querySelector("#blocktoDate").blur();
  };
  const onFocussfromtime = () => {
    intimeobj?.show();
    // document.querySelector("#blockintime").blur();
  };
  const onFocusstotime = () => {
    outtime?.show();
    //document.querySelector("#blockouttime").blur();
  };
  const clearData = (event) => {
    let todate = new Date(new Date().setDate(new Date().getDate() + 1));
    setFormValues((pre) => ({ ...pre, blockfromDate: new Date(), blocktoDate: todate, GHName: "", blockroom: "" }));
    setblockedRooms([]);
    setRoomdataDetails([]);
    setintime_change("12:00 PM");
    setouttime_change("11:59 AM");
    document.querySelector("#blockroom").value = "";
    //setSelected([]);
    window.location.reload();
  };
  const onoutTimeChange = (args) => {
    timecheck({
      intime: timeformatcheck(document.querySelector("#blockintime")?.value),
      outtime: timeformatcheck(document.querySelector("#blockouttime")?.value),
    });
  };

  const timecheck = (args) => {
    let stimechange = document.querySelector("#StartTime")?.value;
    let etimechange = document.querySelector("#EndTime")?.value;
    let chntime = document.querySelector("#blockintime")?.value;
    let ottime = document.querySelector("#blockouttime")?.value;
    settimediff(5);

    if (stimechange === etimechange) {
      let diff = args?.outtime - args?.intime;

      if (diff < 4) {
        setguesterrormsg(["Minimum 4 hours required for booking"]);
        setTimeout(() => {
          setguesterrormsg([]);
        }, 2000);
      }
      settimediff(diff);
    }
  };
  const timeformatcheck = (value) => {
    const [time, modifier] = value.split(" ");

    let [hours, minutes] = time.split(":");

    if (parseInt(hours) === 12 && modifier === "AM") {
      hours = 0;
    } else if (parseInt(hours) < 12 && modifier === "AM") {
      hours = parseInt(hours);
    } else if (parseInt(hours) === 12 && modifier === "PM") {
      hours = parseInt(hours);
    } else {
      hours = parseInt(hours) + 12;
    }

    return hours;
    //return `${hours}:${minutes}`;
  };
  const onTimeChange = (args) => {};
  //console.log(groupdata)
  const BlockCheckDetailsfunc = (value) => {
    isLoadingfunc(true);

    // for (let k = 0; k < value?.length; k++) {
    let blockcheckData = {
      RoomId: value.toString(),
      FromDt: document.querySelector("#blockfromDate")?.value,
      ToDt: document.querySelector("#blocktoDate")?.value,
      FromTime: "12:00:00 PM" || document.querySelector("#blockintime")?.value,
      ToTime: "11:59:00 AM" || document.querySelector("#blockouttime")?.value,
      CheckIn24Hrs: 0,
      UserId: ghuserId,
    };
    // console.log(blockcheckData);
    axios
      .post(`${proxy_Url}/BlockblukCheckDetail`, blockcheckData, { headers: headers })
      .then(({ data }) => {
        // console.log(data);
        isLoadingfunc(false);
        if (data?.Result === "No records found") {
          //  settoblockdata(pre => [...pre,value]);
          isLoadingfunc(false);
        }
      })
      .catch((error) => {
        // console.log(error.message);
        isLoadingfunc(false);
      });
    // }
  };
  const selectallfunc = (args) => {
    let filterrooms = roomdataDetails.map((item) => item?.RoomNoId);
    let filterroomsid = roomdataDetails.map((item) => item?.RoomId);

    if (selectedall[0] !== "Select All") {
      setSelected(filterrooms);
      // console.log("sfsfs")
      // BlockCheckDetailsfunc(filterroomsid);
    } else {
      setSelected([]);
    }
  };
  const handleOnChange = (args) => {};
  // console.log(blockmodify);
  return (
    <React.Fragment>
      {is_loading && <SpinnerTemplate />}
      {getErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className={`error_MSg_Con`}>
              <span>Error Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
       </div> */}
            </div>
            <div className="error_content">
              {getErrorMessage?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}

      {errormsg?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className={`error_MSg_Con`}>
              <span>Error Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
      </div> */}
            </div>
            <div className="error_content">
              {errormsg &&
                errormsg?.map((item, i) => (
                  <p className="t-center" style={{ width: "100% !important" }} key={i}>
                    {item && ` ${item}`}
                  </p>
                ))}
            </div>
          </div>
        </div>
      )}

      {bookinggetErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className={`error_MSg_Con`}>
              <span>Error Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
      </div> */}
            </div>
            <div className="error_content">
              {bookinggetErrorMessage &&
                bookinggetErrorMessage?.map((item, i) => (
                  <p className="t-center" style={{ width: "100% !important" }} key={i}>
                    {item && ` ${item}`}
                  </p>
                ))}
            </div>
          </div>
        </div>
      )}

      {bookinggetsuccessErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className={`error_MSg_Con error-success`}>
              <span>Success Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
      </div> */}
            </div>
            <div className="error_content">
              {bookinggetsuccessErrorMessage &&
                bookinggetsuccessErrorMessage?.map((item, i) => (
                  <p className="t-center" style={{ width: "100% !important" }} key={i}>
                    {item && ` ${item}`}
                  </p>
                ))}
            </div>
          </div>
        </div>
      )}

      {blockmodify?.length > 0 && <BlockModifyData ghnamechange={ghnamechange} />}
      {blockhistory?.length > 0 && <BlockHistory />}
      <Head title="Guest house booking"></Head>
      {/* {showerror_template && <Errow_page />} */}
      <Content>
        <h3 className="page_title">Room Block</h3>
        <form onSubmit={onSubmit}>
          <div className="bg-white p-3 card-bordered b-form-details roomblock">
            <div className="row">
              <div className="col-3">
                <div className="row">
                  <div className="col-md-12">
                    <label className="e-textlabel">Blocked From Date</label>
                    <div className="form-control-wrap">
                      <DatePickerComponent
                        id="blockfromDate"
                        format="dd/MM/yyyy"
                        data-name="blockfromDate"
                        onFocus={onFocussdate}
                        onChange={(e) => HandleChange(e)}
                        name="blockfromDate"
                        ref={(date) => (startObj = date)}
                        max={maxdate}
                        value={formValues.blockfromDate || new Date()}
                        className="form-control"
                      ></DatePickerComponent>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="e-textlabel">Blocked To Date</label>
                    <div className="form-control-wrap">
                      <DatePickerComponent
                        id="blocktoDate"
                        format="dd/MM/yyyy"
                        data-name="blocktoDate"
                        onFocus={onFocusstodate}
                        min={mindate}
                        onChange={HandleoutChange}
                        name="blocktoDate"
                        ref={(date) => (endObj = date)}
                        value={formValues.blocktoDate || new Date()}
                        className="form-control"
                      ></DatePickerComponent>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="Check_In_Date">Blocked From Time *</label>

                    <TimePickerComponent
                      id="blockintime"
                      openOnFocus={true}
                      onClick={onFocussfromtime}
                      onChange={onTimeChange}
                      min={"12:00 PM"}
                      //selected={"12:00 PM"  }
                      ref={(scope) => (intimeobj = scope)}
                      step={60}
                      value={intime_change || "12:00 PM"}
                      placeholder="Select a Time"
                      // focus={onFocussfromtime}
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="Check_In_Date">Blocked From Time *</label>

                    <TimePickerComponent
                      id="blockouttime"
                      openOnFocus={true}
                      onClick={onFocusstotime}
                      onChange={onoutTimeChange}
                      min={intime_change || "11:59 AM"}
                      //selected={"12:00 PM"  }
                      ref={(scope) => (outtime = scope)}
                      step={60}
                      value={outtime_change || "11:59 AM"}
                      placeholder="Select a Time"
                      // focus={onFocusstotime}
                    />
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="row">
                  <div className="col-md-12">
                    <label className="e-textlabel">
                      Guest House <span className="c-red">*</span>
                    </label>

                    <AutoCompleteComponent
                      id="booking_ghname"
                      value={formValues.GHName}
                      placeholder="Select the Guest House"
                      className="form-control"
                      onChange={ghnamechange}
                      dataSource={ghName && ghName[0]?.map((item, key) => item.Property)}
                      filtering={ghnamechange}
                      // onSelect={ghnamechange}
                      ref={propertyloadref}
                      name="GHName"
                      // onInput={propInput}
                      // onSelect={ghSelect}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="e-textlabel">
                      Comments <span className="c-red">*</span>
                    </label>
                    <textarea
                      type="text"
                      name="blockroom"
                      defaultValue={formValues.blockroom || ""}
                      className="form-control hide_emp"
                      id="blockroom"
                      // onChange={handelChange}
                      style={{ minHeight: "185px" }}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="col-6 block-room-blk">
                <div className="row">
                  <div className="col-md-12">
                    <label className="e-textlabel">
                      Select Block Room Name <span className="c-red">*</span>
                    </label>
                    {/* {roomdataDetails?.length > 0 && (
                      <CheckboxGroup value={selectedall} onChange={selectedallfunc} className="chx-box">
                        <Checkbox value={"Select All"} style={{ float: "right" }} onChange={selectallfunc}>
                          {" "}
                          &nbsp;{"Select All"}
                        </Checkbox>
                      </CheckboxGroup>
                    )} */}
                  </div>
                  <CheckboxGroup
                    className="toppings-list"
                    label="Favorite sports (controlled)"
                    value={selected}
                    onChange={setSelected}
                  >
                    {roomdataDetails?.map((item) => (
                      <Checkbox key={item?.RoomId} value={item?.RoomNoId}>
                        {" "}
                        &nbsp;{item?.RoomNoId}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </div>
              </div>

              <div className="col-md-12 text-center mt-3 border-top">
                <div className="btnGrp mt-2">
                  <button type="button" className="btn btn-sm btn-success m-1" onClick={onSubmit}>
                    Block
                  </button>
                  {/* <button type="button" className="btn btn-sm btn-danger m-6" onClick={onDownloadfunc}>
                    Export
                  </button> */}
                  <button type="button" className="btn btn-sm btn-danger m-1" onClick={clearData}>
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* {bookingDetails && bookingDetails.length > 0 ? ( */}
        <>
          <div className="row">
            <div className="col-md-12 tr_list_table">
              <div className="o_flow h-26 datashow bg-white">
                <table
                  role="table"
                  className="table table-hover table-bordered tr_select booking_details b-shadow w-100"
                >
                  <thead role="rowgroup">
                    <tr role="row" className="stickyheader">
                      <th role="columnheader">Room&nbsp;No</th>
                      <th role="columnheader">From Date</th>
                      <th role="columnheader">To Date</th>
                      <th role="columnheader">Comments</th>
                      <th role="columnheader">Modify</th>
                      <th role="columnheader" style={{ width: "100px" }}>
                        Unblock
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    {blockedRooms ? (
                      blockedRooms.map((val, index) => (
                        <tr role="row" key={index}>
                          <td role="cell">{val.RoomNo}</td>
                          <td role="cell" style={{ whiteSpace: "nowrap" }}>
                            {val.FromDt + " " + val.FromTime}
                          </td>
                          <td role="cell" style={{ whiteSpace: "nowrap" }}>
                            {val.ToDt + " " + val.ToTime}
                          </td>
                          <td role="cell">{val.Comments}</td>

                          <td role="cell" style={{ width: "150px" }}>
                            <button
                              className="selectsingleTr btn btn-primary btn-sm"
                              onClick={(e) => modify(val)}
                              disabled={val.CurrentStatus === "CheckIn" ? true : false}
                              style={{ marginRight: "5px" }}
                            >
                              Modify
                            </button>
                            <button
                              className="selectsingleTr btn btn-primary btn-sm"
                              onClick={(e) => history(val)}
                              disabled={val.CurrentStatus === "CheckIn" ? true : false}
                            >
                              History
                            </button>
                          </td>
                          <td role="cell">
                            <button
                              className="selectsingleTr btn btn-primary btn-sm"
                              onClick={(e) => unblock(val)}
                              disabled={val.CurrentStatus === "CheckIn" ? true : false}
                            >
                              Unblock
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <p className="d-flex text-align-center justify-content-center mt-3">
                        Select Guest House Name and click search to display data
                      </p>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
        {/* ) : ( */}

        {/* )} */}
      </Content>
    </React.Fragment>
  );
};

export default RoomBlock;
