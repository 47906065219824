import React, { useState, useContext } from "react";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, Button, Icon, PreviewCard } from "../../components/Component";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { BookingContent } from "../../rcomponents/bookingContext/BookingContext";
import axios from "axios";
//import { useHistory } from "react-router-dom";

const Login = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [newpassState, setNewPassState] = useState(false);
  const [cnfnewpassState, setCnfNewPassState] = useState(false);
  const [errorVal, setError] = useState("");

  const [pwd, setPwd] = useState("");
  const [cnfpwd, setCnfPwd] = useState("");
  const [oldpwd, setOldPwd] = useState("");

  const { token, proxy_Url, getLoginAuth, propInput } = useContext(BookingContent);

  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };
  //const history = useHistory();
  const onFormSubmit = (e) => {
    setLoading(true);

    if (e.newpasscode !== e.cnfnewpasscode) {
      alert("Passwords don't match");
      setLoading(false);
    } else {
      // make API call

      let bodyData = {
        NewPassword: `${e.cnfnewpasscode}`,
        OldPassword: `${e.oldpassword}`,
        UserId: ghuserId,
      };

      axios
        .post(`${proxy_Url}/changepwd`, bodyData, { headers: headers })
        .then((res) => {
          let Rdata = res.data.Result;

          localStorage.removeItem("accessToken");
          localStorage.removeItem("accessToken1");
          sessionStorage.removeItem("credentials");
          getLoginAuth({});
          setLoading(false);
        })
        .catch((err) => {
          // console.log(err.message);
          setLoading(false);
        });
      setTimeout(() => history.push(`${process.env.PUBLIC_URL}/auth-success`), 2000);
    }
  };
  const { errors, register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <Head title="Change Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <PreviewCard className="card-bordered login-logo" bodyClass="card-inner-lg">
            <div className="brand-logo pb-1 text-center">
              <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                <img
                  className="logo-dark"
                  src="https://endpoint887127.azureedge.net/pimages/OrTFH2wg_newhblogo_250x150.png"
                  alt="logo-dark"
                />
              </Link>
            </div>
            {/* {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> Unable to login with credentials{" "}
                </Alert>
              </div>
            )} */}

            <h5 className="text-center fw-bold mt-0">Change Password</h5>
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup className="mb-0">
                <div className="form-label-group">
                  <label className="form-label mt-0" htmlFor="default-01">
                    Old Password
                  </label>
                </div>
                {/* <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-01"
                    name="name"
                    ref={register({ required: "Old Passowrd is required" })}
                    //defaultValue="info@softnio.com"
                    value={uName}
                    onChange={(e) => setUName(e.target.value)}
                    placeholder="Username"
                    className="form-control-lg form-control"
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div> */}
                <div className="form-control-wrap">
                  <a
                    href="#oldpassword"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="oldpassword"
                    name="oldpassword"
                    value={oldpwd}
                    onChange={(e) => setOldPwd(e.target.value)}
                    ref={register({ required: "Old Password is required" })}
                    placeholder="Enter Old Password"
                    onInput={propInput}
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  />
                  {errors.oldpassword && <span className="invalid">{errors.oldpassword.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    New Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#newpassword"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setNewPassState(!newpassState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${
                      newpassState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={newpassState ? "text" : "password"}
                    id="newpassword"
                    name="newpasscode"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    ref={register({ required: "New Password is required" })}
                    placeholder="Enter New Password"
                    onInput={propInput}
                    className={`form-control-lg form-control ${newpassState ? "is-hidden" : "is-shown"}`}
                  />
                  {errors.newpasscode && <span className="invalid">{errors.newpasscode.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Confirm New Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#cnfnewpassword"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setCnfNewPassState(!cnfnewpassState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${
                      cnfnewpassState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={cnfnewpassState ? "text" : "password"}
                    id="cnfnewpassword"
                    name="cnfnewpasscode"
                    value={cnfpwd}
                    onChange={(e) => setCnfPwd(e.target.value)}
                    ref={register({ required: "Confirm New Password is required" })}
                    placeholder="Enter Confirm New Password"
                    className={`form-control-lg form-control ${cnfnewpassState ? "is-hidden" : "is-shown"}`}
                  />
                  {errors.cnfnewpasscode && <span className="invalid">{errors.cnfnewpasscode.message}</span>}
                </div>
                <div className="form-label-group mt-2">
                  <label className="form-label" htmlFor="password">
                    {" "}
                  </label>
                  <Link
                    className="link link-primary link-sm room-green c-white"
                    style={{ fontSize: "14px", padding: "3px", background: "#ddd" }}
                    to={`${process.env.PUBLIC_URL}/`}
                  >
                    Back to home
                  </Link>
                </div>
              </FormGroup>
              <FormGroup>
                <Button size="lg" className="btn-block" type="submit" color="primary">
                  {loading ? <Spinner size="sm" color="light" /> : "Change Password"}
                </Button>
              </FormGroup>
            </Form>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
