import React, { useEffect, useContext, useState, useRef, useCallback } from "react";
import "../../assets/scss/changeEditor.scss";
import { BookingContent } from "../bookingContext/BookingContext";
import axios from "axios";
import { DatePickerComponent, TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import CustomFieldsEdit from "../editorTableComponent/CustomFieldsEdit";
import { setTime } from "@syncfusion/ej2-react-schedule";
import moment from "moment";

const ChangeEventTemplate = ({ showChangedatafunc }) => {
  const {
    setSchedulerData,
    scheduledataoriginal,
    gridoccupencydatafunc,
    property_details,
    setCancelEvent,
    token,
    show_error_templatefunc,
    getErrorMessagefunc,
    errorClassNamefunc,
    isLoadingfunc,
    proxy_Url,
    change_event_data,
    editmodificationfunc,
    editmodificationdata,
    staychangedate,
    changeeventdatafunc,
    editdatafunc,
    ghaccessdata,
    set_Error_Message_func,
    propertyID,
    setErrorMsgClassfunc,
    client_id,
    clientID,
    setstaychangedate,
    clientcustomfieldscollection,
    edit_cus_data,
    propInput,
    stayshift,
    GH_property_ClientName,
    stayshiftfunc,
    roomshiftdata,
    changeroomid,
    editmodicheckfunc,
    editmodicheck,
  } = useContext(BookingContent);

  //set session id

  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  sessionStorage.setItem("access_Token1", localStorage.getItem("accessToken1"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;
  let remarks = useRef();
  let intimeobj = useRef();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [e_customFields, setE_customFields] = useState(false);
  const [getErrorMessage, setgetErrorMessagefunc] = useState([]);
  const [get_ErrorMessage, setget_ErrorMessagefunc] = useState([]);
  const [getroomerrormsg, setgetroomerrormsg] = useState([]);
  const [err_msg, seterr_msg] = useState("");
  const [edit_modi_check, setedit_modi_check] = useState([]);
  const [changelog, setchangelog] = useState([]);
  const [showchangelog, setshowchangelog] = useState(false);
  const [msg_option, setmsg_option] = useState([]);
  const [bedstay, setbedstay] = useState("");
  const [bedidavailablecheckcount, setbedidavailablecheckcount] = useState([]);
  const [editmodificationfilterdata, seteditmodificationfilterdata] = useState([]);
  const [end_Date, setend_Date] = useState(null);
  const [choutdate, setchoutdate] = useState(null);
  const [time_change, settime_changefunc] = useState("");

  let ghproperty_Name = document.querySelector("#ghproperty");
  let ghproperty_Entity = document.querySelector("#Entity_select");
  let endmodifyObj = useRef();
  let startmodifyObj = useRef();

  let mincheckindate;

  //get bet count

  useEffect(() => {
    setTimeout(() => {
      let st_date = document.querySelector("#edit_s_date")?.value;
      let e_date = document.querySelector("#edit_e_date")?.value;
      if (st_date === e_date) {
        let stdate = st_date.split("/");
        let splitstdate = `${stdate[1]},${stdate[0]},${stdate[2]}`;

        setEndDate(new Date(new Date(splitstdate).setDate(new Date(splitstdate).getDate() + 1)));
      }
    }, 500);
  }, [change_event_data]);

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };

  //start date focus
  const startonFocusmodify = (args) => {
    startmodifyObj.show();
    document.querySelector("#edit_s_date").blur();
  };
  //End date focus
  const EndonFocusmodify = (args) => {
    endmodifyObj.show();

    document.querySelector("#edit_e_date").blur();
  };

  const changeDatafunc = () => {
    if (changelog?.length > 0) {
      setshowchangelog(true);
    } else {
      showChangedatafunc(false);
      changeeventdatafunc([]);
      setCancelEvent(false);
      setSchedulerData(scheduledataoriginal[0]);
    }
  };

  const dragChangeData = useCallback(() => {
    let bodyData = {
      RoomId: change_event_data?.RoomId,
      BookingCode: change_event_data?.BookingCode,
      UserId: ghuserId,
    };

    axios
      .post(`${proxy_Url}/GetBookingModificationDetails`, bodyData, { headers: headers })
      .then((res) => {
        // editdatafunc(res.data);

     
        let editfilter = res.data?.Result?.filter(
          (item) => parseInt(item.RoomId) === parseInt(change_event_data?.BedId)
        );

       
        editmodificationfunc(res.data);
        seteditmodificationfilterdata(editfilter);

        //  if(stayshift === 'dragevent'){
        setedit_modi_check([]);

        //modification data check start

        setedit_modi_check([]);
        
          let sd = document.querySelector("#edit_s_date")?.value.split("/");
          let ed = document.querySelector("#edit_e_date")?.value.split("/");
          
          if (setstaychangedate?.BookingLevel === "Bed") {
           
            let bodydata = {
              BookingId: setstaychangedate?.BookingId,
              RoomId:
                setstaychangedate?.BookingLevel === "Bed"
                  ? changeroomid[0]?.RoomId || setstaychangedate?.RoomId
                  : setstaychangedate?.BedId,

              CheckInDt: sd[2] + "-" + sd[1] + "-" + sd[0] + " " + setstaychangedate?.Start_Time,
              CheckOutDt: ed[2] + "-" + ed[1] + "-" + ed[0] + " " + setstaychangedate?.End_Time,
              BedId: editfilter[0]?.BookingAssingedId,
            };
           
            setbedidavailablecheckcount([]);
            if (stayshift === "dragevent") {
              axios
                .post(`${proxy_Url}/GHRoomShiftingBedLoad`, bodydata, { headers: headers })
                .then((res) => {
                  
                  setedit_modi_check(res.data?.Result[0][0]);
                  editmodicheckfunc(res.data?.Result[0][0] !== undefined && res.data?.Result[0][0]);
                  
                 
                  let bedavailablecount = res.data?.Result[0]?.filter(
                    (item) => item?.RoomId == setstaychangedate?.BedId
                  );

                  setbedidavailablecheckcount(bedavailablecount);
                  if (bedavailablecount[0].length === 1) {
                    setedit_modi_check(bedavailablecount);
                    setbedstay("bedStay");
                  } else {
                    setbedstay("bedShift");
                  }
                })
                .catch((error) => {});
              // bed shift hide 123
            }
          }
      

        //modification data end

        // }

        // let s_date = document.querySelector("#STime")?.value.split("/");
        // let e_date = document.querySelector("#ETime")?.value.split("/");

        // let bodyData1 = {
        //   PropertyId: property_details[0]?.Id || propertyID[0],
        //   FromDte: s_date[1] + "/" + s_date[0] + "/" + s_date[2],
        //   ToDte: e_date[1] + "/" + e_date[0] + "/" + e_date[2],
        //   PropertyName: property_details[0]?.Property || document.querySelector("#ghproperty")?.value,
        // };

        // gridoccupencydatafunc(bodyData1);
      })
      .catch((error) => {});
  }, [change_event_data]);

  const editmodifyavailable = () => {};

  const dateconversionyymmdd = (value) => {
    var d = new Date(value),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  useEffect(() => {
    setTimeout(() => {
      setend_Date(endDate);
    }, 2000);
  }, [endDate]);

  useEffect(() => {
    //call editmodifyavailable

    // if(stayshift === 'dragevent'){
    //   setedit_modi_check([]);
    // editmodifyavailable();
    // }

    if (ghaccessdata[0]?.ModifyResFlg === false) {
      setgetErrorMessagefunc([]);
      setgetErrorMessagefunc(["You dont have access to modify this booking"]);
      setTimeout(() => {
        setgetErrorMessagefunc([]);
        error_close();
      }, 2000);
    }

    setTimeout(() => {
      let get_change_date = staychangedate;

      setStartDate(new Date(get_change_date.start_date));

      if (get_change_date.start_date === get_change_date.end_date) {
        setEndDate(new Date(get_change_date.diff_date));
      } else {
        setEndDate(new Date(get_change_date.end_date));
      }

      isLoadingfunc(false);
    }, 100);

    let ss = change_event_data?.CheckInDt;
    let es = change_event_data?.CheckOutDt;
    if (ss) {
      let s_date = ss.split("/");
      let s_date_split = s_date[1] + "/" + s_date[0] + "/" + s_date[2];

      setStartDate(s_date_split);
      let e_date = es.split("/");
      let e_date_split = new Date(e_date[1], e_date[0], e_date[2]);
      setEndDate(e_date_split);
    }
    dragChangeData();
  }, [staychangedate]);

  const setStartDatefunc = (e) => {
    setStartDate(new Date(e));
    let edate = new Date(new Date(e).setDate(new Date(e).getDate() + 1));
    setEndDate(edate);

    // setStartDate
  };

  const editCustomField = () => {
    setE_customFields(!e_customFields);
  };

  const onSubmit = (data) => {};

  const checkModify = () => {
    let soutput_date = document.querySelector("#edit_s_date").value;
    let eoutput_date = document.querySelector("#edit_e_date").value;

    let s_datecheck = soutput_date.split("/");
    let s_date_splitcheck = s_datecheck[1] + "/" + s_datecheck[0] + "/" + s_datecheck[2];
    let e_datecheck = eoutput_date.split("/");
    let e_date_splitcheck = e_datecheck[1] + "/" + e_datecheck[0] + "/" + e_datecheck[2];

    //const d = new Date("03,24,2023");
    const dt1 = new Date(`${s_datecheck[1]},${s_datecheck[0]},${s_datecheck[2]},`);
    const dt2 = new Date(`${e_datecheck[1]},${e_datecheck[0]},${e_datecheck[2]},`);
    let modifydatediff = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
    //check for same id

    let blocknameadd;
if(roomshiftdata[0]?.text.includes("- Single")) {
  let roomname = (roomshiftdata[0]?.BlockName + " - " +roomshiftdata[0]?.text.replace("- Single",""))
  blocknameadd = roomname.includes("Apartment") ? roomname.replace("Apartment","") : roomname;
}
else if (roomshiftdata[0]?.text.includes("- Double")){
  let roomname = (roomshiftdata[0]?.BlockName + " - " +roomshiftdata[0]?.text.replace("- Double",""))
  blocknameadd = roomname.includes("Apartment") ? roomname.replace("Apartment","") : roomname;
 
}else {
 
  let roomname = roomshiftdata[0]?.BlockName + " - " +roomshiftdata[0]?.text;
  blocknameadd = roomname.includes("Apartment") ? roomname.replace("Apartment","") : roomname;
}

    let frmroomid =
      setstaychangedate?.BookingLevel === "Bed"
        ? parseInt(setstaychangedate?.BedId)
        : parseInt(editmodificationdata?.Result[0]?.RoomId);
    let toroomid =
      roomshiftdata?.length > 0
        ? roomshiftdata[0]?.RoomId
        : setstaychangedate?.BookingLevel === "Bed"
        ? stayshift === "resizeevent"
          ? parseInt(editmodificationdata?.Result[0]?.RoomId)
          : parseInt(edit_modi_check?.RoomId)
        : parseInt(editmodificationdata?.Result[0]?.RoomId);

    let roomdata = {
      BookingId: editmodificationdata?.Result[0]?.BookingId,
      BookingLevel: editmodificationdata?.Result[0]?.BookingLevelId, // Room or Bed
      CheckInDt: soutput_date,
      CheckOutDt: eoutput_date,
      ChkInTime: editmodificationdata?.Result[0]?.CheckInTime1,
      ChkOutTime:
        // change_event_data?.Type === "CheckIn" ? document.querySelector("#checkintime").value :
        editmodificationdata?.Result[0]?.CheckOutTime1,
      FromRoomId:
        setstaychangedate?.BookingLevel === "Bed"
          ? parseInt(setstaychangedate?.BedId)
          : parseInt(editmodificationdata?.Result[0]?.RoomId),
      ToRoomId:
        editmodificationdata?.Result[0]?.CurrentStatus === "CheckIn"
          ? frmroomid
          : roomshiftdata?.length > 0
          ? roomshiftdata[0]?.RoomId
          : setstaychangedate?.BookingLevel === "Bed"
          ? stayshift === "resizeevent"
            ? parseInt(editmodificationdata?.Result[0]?.RoomId)
            : parseInt(edit_modi_check?.RoomId)
          : parseInt(editmodificationdata?.Result[0]?.RoomId),
      RoomCaptured:
        1 || stayshift === "resizeevent"
          ? 1
          : 2 || editmodificationdata?.Result[0]?.RoomCapturedId || edit_modi_check?.RoomId
          ? 2
          : 1,
      CurrentStatus: editmodificationdata?.Result[0]?.CurrentStatus,
      TariffMode: editmodificationdata?.Result[0]?.TariffMode,
      ServiceMode: editmodificationdata?.Result[0]?.ServiceMode,
      Remarks: document.querySelector("#remarks").value,
      Type:
        editmodificationdata?.Result[0]?.CurrentStatus === "CheckIn"
          ? "Stay"
          : frmroomid == toroomid
          ? "Stay"
          : roomshiftdata?.length > 0
          ? "Shift"
          : (soutput_date === chInDate || eoutput_date === chkOutDate) === true
          ? "Stay"
          : "Shift",
      ToRoomNo:
        roomshiftdata?.length > 0
          ? blocknameadd.trim()
          //roomshiftdata[0]?.text
          : (edit_modi_check?.label !== undefined && edit_modi_check?.label) || staychangedate?.RoomName,
      UserId: ghuserId,
      Column1: editmodificationdata?.CustomFieldDetails[0][0]?.Column1 || "",
      Column2: editmodificationdata?.CustomFieldDetails[0][0]?.Column2 || "",
      Column3: editmodificationdata?.CustomFieldDetails[0][0].Column3 || "",
      Column4: editmodificationdata?.CustomFieldDetails[0][0].Column4 || "",
      Column5: editmodificationdata?.CustomFieldDetails[0][0].Column5 || "",
      Column6: editmodificationdata?.CustomFieldDetails[0][0].Column6 || "",
      Column7: editmodificationdata?.CustomFieldDetails[0][0].Column7 || "",
      Column8: editmodificationdata?.CustomFieldDetails[0][0].Column8 || "",
      Column9: editmodificationdata?.CustomFieldDetails[0][0].Column9 || "",
      Column10: editmodificationdata?.CustomFieldDetails[0][0].Column10 || "",
      BookingAssingedId:
        editmodificationdata?.CustomFieldDetails[0][0]?.BookingAssingedId ||
        editmodificationdata?.CustomFieldDetails[0]?.BookingAssingedId ||
        "",
      Column21: "",
      Column22: "",
      Column23: "",
      Column24: "",
      Column25: "",
      Column26: "",
      Column27: "",
      Column28: "",
      Column29: "",
      Column30: "",
      BookingAssingedId2: 0,
    };
    //editmodificationfilterdata
  
    //  console.log(bedidavailablecheckcount?.RoomId);

    // console.log(parseInt(setstaychangedate?.BedId)==parseInt(stayshift === "resizeevent"
    // ? parseInt(setstaychangedate?.BedId):
    //edit_modi_check?.RoomId || bedidavailablecheckcount?.RoomId))
//     let beddata = {
//       BookingId: editmodificationfilterdata[0]?.BookingId !== undefined ?editmodificationfilterdata[0]?.BookingId : editmodificationdata?.Result
// [0]?.BookingId,
//       BookingLevel: editmodificationfilterdata[0]?.BookingLevelId !== undefined ? editmodificationfilterdata[0]?.BookingLevelId : editmodificationdata?.Result
//       [0]?.BookingLevelId, // Room or Bed
//       FromRoomId: parseInt(setstaychangedate?.BedId),
//       ToRoomId:
//         stayshift === "resizeevent"
//           ? parseInt(setstaychangedate?.BedId)
//           : edit_modi_check?.RoomId || editmodicheck?.RoomId,
//       //  ||stayshift === 'resizeevent' ? 1 : 2 || editmodificationfilterdata[0]?.RoomCapturedId || edit_modi_check?.RoomId ? 2 : 1,
//       Type:
//         parseInt(setstaychangedate?.BedId) ==
//         parseInt(
//           stayshift === "resizeevent"
//             ? parseInt(setstaychangedate?.BedId)
//             : edit_modi_check?.RoomId || bedidavailablecheckcount?.RoomId
//         )
//           ? "Stay"
//           : "Shift",
//       RoomCaptured: editmodificationfilterdata[0]?.RoomCapturedId || editmodificationdata?.Result[0]?.RoomCapturedId,
//       // setstaychangedate?.BookingLevel === "Room"
//       //   ? (soutput_date === chInDate || eoutput_date === chkOutDate) === true
//       //     ? "Stay"
//       //     : "Shift"
//       //   : bedstay === "bedStay"
//       //   ? "Stay"
//       //   : stayshift === "resizeevent"
//       //   ? "Stay"
//       //   : parseInt(setstaychangedate?.BedId) == parseInt(edit_modi_check?.RoomId)
//       //   ? "Stay"
//       //   : "Shift",
//       ToRoomNo: (edit_modi_check?.label !== undefined && edit_modi_check?.label) || staychangedate?.RoomName,

//       CheckInDt: soutput_date,
//       CheckOutDt: eoutput_date,
//       ChkInTime: editmodificationfilterdata[0]?.CheckInTime1 !== undefined ? editmodificationfilterdata[0]?.CheckInTime1 : editmodificationdata?.Result
//       [0]?.CheckInTime1,
//       ChkOutTime:
//         //change_event_data?.Type === "CheckIn" ? document.querySelector("#checkintime").value :
//         editmodificationfilterdata[0]?.CheckOutTime1  !== undefined ? editmodificationfilterdata[0]?.CheckOutTime1 : editmodificationdata?.Result
//         [0]?.CheckOutTime1,

//       // ToRoomId: setstaychangedate?.BookingLevel ==="Bed" ?  stayshift === 'resizeevent' ? parseInt(editmodificationfilterdata[0]?.RoomId) : parseInt(edit_modi_check?.RoomId)  : parseInt(editmodificationfilterdata[0]?.RoomId),

//       CurrentStatus: editmodificationfilterdata[0]?.CurrentStatus  !== undefined ? editmodificationfilterdata[0]?.CurrentStatus : editmodificationdata?.Result
//       [0]?.CurrentStatus,
//       TariffMode: editmodificationfilterdata[0]?.TariffMode  !== undefined ? editmodificationfilterdata[0]?.TariffMode : editmodificationdata?.Result
//       [0]?.TariffMode,
//       ServiceMode: editmodificationfilterdata[0]?.ServiceMode  !== undefined ? editmodificationfilterdata[0]?.ServiceMode : editmodificationdata?.Result
//       [0]?.ServiceMode,
//       Remarks: document.querySelector("#remarks").value,
//       // Type: setstaychangedate?.BookingLevel ==="Room" ? (soutput_date === chInDate || eoutput_date === chkOutDate) === true ? "Stay" : "Shift" :bedstay === "bedStay" ? "Stay" : "Shift",

//       UserId: ghuserId,
//       Column1: editmodificationdata?.CustomFieldDetails[0][0]?.Column1 || "",
//       Column2: editmodificationdata?.CustomFieldDetails[0][0]?.Column2 || "",
//       Column3: editmodificationdata?.CustomFieldDetails[0][0].Column3 || "",
//       Column4: editmodificationdata?.CustomFieldDetails[0][0].Column4 || "",
//       Column5: editmodificationdata?.CustomFieldDetails[0][0].Column5 || "",
//       Column6: editmodificationdata?.CustomFieldDetails[0][0].Column6 || "",
//       Column7: editmodificationdata?.CustomFieldDetails[0][0].Column7 || "",
//       Column8: editmodificationdata?.CustomFieldDetails[0][0].Column8 || "",
//       Column9: editmodificationdata?.CustomFieldDetails[0][0].Column9 || "",
//       Column10: editmodificationdata?.CustomFieldDetails[0][0].Column10 || "",
//       BookingAssingedId:
//         editmodificationfilterdata[0]?.BookingAssingedId ||
//         editmodificationdata?.CustomFieldDetails[0][0]?.BookingAssingedId ||
//         editmodificationdata?.CustomFieldDetails[0]?.BookingAssingedId ||
//         "",
//       Column21: "",
//       Column22: "",
//       Column23: "",
//       Column24: "",
//       Column25: "",
//       Column26: "",
//       Column27: "",
//       Column28: "",
//       Column29: "",
//       Column30: "",
//       BookingAssingedId2: 0,
//     };
    let beddata = {
      BookingId:editmodificationfilterdata[0]?.BookingId,
      BookingLevel:  editmodificationfilterdata[0]?.BookingLevelId, // Room or Bed
      FromRoomId: parseInt(setstaychangedate?.BedId),
      ToRoomId:
        stayshift === "resizeevent"
          ? parseInt(setstaychangedate?.BedId)
          : edit_modi_check?.RoomId || editmodicheck?.RoomId,
      //  ||stayshift === 'resizeevent' ? 1 : 2 || editmodificationfilterdata[0]?.RoomCapturedId || edit_modi_check?.RoomId ? 2 : 1,
      Type:
        parseInt(setstaychangedate?.BedId) ==
        parseInt(
          stayshift === "resizeevent"
            ? parseInt(setstaychangedate?.BedId)
            : edit_modi_check?.RoomId || bedidavailablecheckcount?.RoomId
        )
          ? "Stay"
          : "Shift",
      RoomCaptured: editmodificationfilterdata[0]?.RoomCapturedId,
      // setstaychangedate?.BookingLevel === "Room"
      //   ? (soutput_date === chInDate || eoutput_date === chkOutDate) === true
      //     ? "Stay"
      //     : "Shift"
      //   : bedstay === "bedStay"
      //   ? "Stay"
      //   : stayshift === "resizeevent"
      //   ? "Stay"
      //   : parseInt(setstaychangedate?.BedId) == parseInt(edit_modi_check?.RoomId)
      //   ? "Stay"
      //   : "Shift",
      ToRoomNo: (edit_modi_check?.label !== undefined && edit_modi_check?.label) || staychangedate?.RoomName,

      CheckInDt: soutput_date,
      CheckOutDt: eoutput_date,
      ChkInTime: editmodificationfilterdata[0]?.CheckInTime1,
      ChkOutTime:
        //change_event_data?.Type === "CheckIn" ? document.querySelector("#checkintime").value :
        editmodificationfilterdata[0]?.CheckOutTime1,

      // ToRoomId: setstaychangedate?.BookingLevel ==="Bed" ?  stayshift === 'resizeevent' ? parseInt(editmodificationfilterdata[0]?.RoomId) : parseInt(edit_modi_check?.RoomId)  : parseInt(editmodificationfilterdata[0]?.RoomId),

      CurrentStatus: editmodificationfilterdata[0]?.CurrentStatus,
      TariffMode: editmodificationfilterdata[0]?.TariffMode,
      ServiceMode: editmodificationfilterdata[0]?.ServiceMode,
      Remarks: document.querySelector("#remarks").value,
      // Type: setstaychangedate?.BookingLevel ==="Room" ? (soutput_date === chInDate || eoutput_date === chkOutDate) === true ? "Stay" : "Shift" :bedstay === "bedStay" ? "Stay" : "Shift",

      UserId: ghuserId,
      Column1: editmodificationdata?.CustomFieldDetails[0][0]?.Column1 || "",
      Column2: editmodificationdata?.CustomFieldDetails[0][0]?.Column2 || "",
      Column3: editmodificationdata?.CustomFieldDetails[0][0].Column3 || "",
      Column4: editmodificationdata?.CustomFieldDetails[0][0].Column4 || "",
      Column5: editmodificationdata?.CustomFieldDetails[0][0].Column5 || "",
      Column6: editmodificationdata?.CustomFieldDetails[0][0].Column6 || "",
      Column7: editmodificationdata?.CustomFieldDetails[0][0].Column7 || "",
      Column8: editmodificationdata?.CustomFieldDetails[0][0].Column8 || "",
      Column9: editmodificationdata?.CustomFieldDetails[0][0].Column9 || "",
      Column10: editmodificationdata?.CustomFieldDetails[0][0].Column10 || "",
      BookingAssingedId:
        editmodificationfilterdata[0]?.BookingAssingedId ||
        editmodificationdata?.CustomFieldDetails[0][0]?.BookingAssingedId ||
        editmodificationdata?.CustomFieldDetails[0]?.BookingAssingedId ||
        "",
      Column21: "",
      Column22: "",
      Column23: "",
      Column24: "",
      Column25: "",
      Column26: "",
      Column27: "",
      Column28: "",
      Column29: "",
      Column30: "",
      BookingAssingedId2: 0,
    };

   
    let diffroomdata = {
      BookingId: editmodificationdata?.Result[0]?.BookingId,
      BookingLevel: setstaychangedate?.BookingLevel, //BedorRoom
      CheckInDt: soutput_date,
      CheckOutDt: eoutput_date,
      ChkInTime: editmodificationdata?.Result[0].CheckInTime,
      ChkOutTime:
        //change_event_data?.Type === "CheckIn" ? document.querySelector("#checkintime")?.value :
        editmodificationdata?.Result[0].CheckOutTime,
      FromRoomId:
        setstaychangedate?.BookingLevel === "Bed"
          ? parseInt(setstaychangedate?.BedId)
          : parseInt(editmodificationdata?.Result[0]?.RoomId),
      ToRoomId:
        setstaychangedate?.BookingLevel === "Bed" ?
          
          edit_modi_check?.RoomId !== undefined
            ? edit_modi_check?.RoomId
            : editmodicheck?.RoomId
          : changeroomid[0]?.RoomId,
      RoomCaptured: editmodificationfilterdata[0]?.RoomCapturedId || editmodificationdata?.Result[0]?.RoomCapturedId,
      CurrentStatus: "Booked",
      TariffMode: "Bill to Client",
      ServiceMode: "Direct",
      Remarks: document.querySelector("#remarks")?.value,
      Type: "Shift",
      UserId: ghuserId,
      Column1: editmodificationdata?.CustomFieldDetails[0][0]?.Column1 || "",
      Column2: editmodificationdata?.CustomFieldDetails[0][0]?.Column2 || "",
      Column3: editmodificationdata?.CustomFieldDetails[0][0]?.Column3 || "",
      Column4: editmodificationdata?.CustomFieldDetails[0][0]?.Column4 || "",
      Column5: editmodificationdata?.CustomFieldDetails[0][0]?.Column5 || "",
      Column6: editmodificationdata?.CustomFieldDetails[0][0]?.Column6 || "",
      Column7: editmodificationdata?.CustomFieldDetails[0][0]?.Column7 || "",
      Column8: editmodificationdata?.CustomFieldDetails[0][0]?.Column8 || "",
      Column9: editmodificationdata?.CustomFieldDetails[0][0]?.Column9 || "",
      Column10: editmodificationdata?.CustomFieldDetails[0][0]?.Column10 || "",
      BookingAssingedId:
        editmodificationfilterdata[0]?.BookingAssingedId ||
        editmodificationdata?.CustomFieldDetails[0][0]?.BookingAssingedId ||
        editmodificationdata?.CustomFieldDetails[0]?.BookingAssingedId ||
        "",
      Column21: "",
      Column22: "",
      Column23: "",
      Column24: "",
      Column25: "",
      Column26: "",
      Column27: "",
      Column28: "",
      Column29: "",
      Column30: "",
      BookingAssingedId2: 0,
      ToRoomNo: setstaychangedate?.BookingLevel === "Room" ?  blocknameadd.trim() : (edit_modi_check?.label !== undefined && edit_modi_check?.label),
    };

    let get_remarks_data = document.querySelector("#remarks");
    if (get_remarks_data) {
      let value = get_remarks_data?.value;
      //check booking modification available

      let modifystartdata = document.querySelector("#edit_s_date").value.split("/");
      let modifyenddata = document.querySelector("#edit_e_date").value.split("/");
      let chk_bodyData = {
        PropertyId: `${propertyID[0]}`,
        ClientId: clientID[0]?.length > 0 ? clientID[0] : client_id,
        FromDt:
          modifystartdata ||
          modifystartdata[1] +
            "/" +
            modifystartdata[0] +
            "/" +
            modifystartdata[2] +
            " " +
            editmodificationfilterdata[0]?.CheckInTime1.replace(":00", ":00:00") ||
          " 12:00:00 PM",
        ToDt: modifyenddata || modifyenddata[1] + "/" + modifyenddata[0] + "/" + modifyenddata[2] + " 11:59:00 AM",
        RoomId: `${editmodificationfilterdata[0]?.RoomId}`,
      };

      //check booking modification available
      let s_date = document.querySelector("#STime")?.value.split("/");
      let e_date = document.querySelector("#ETime")?.value.split("/");
      let bodyData = {
        PropertyId: value?.PropertyId || propertyID[0],
        FromDte: s_date[1] + "/" + s_date[0] + "/" + s_date[2],
        ToDte: e_date[1] + "/" + e_date[0] + "/" + e_date[2],
        PropertyName: value?.PropertyName || document.querySelector("#ghproperty")?.value,
      };
      if (value !== "") {
        // if(edit_modi_check?.length > 0) {
        //isLoadingfunc(true);

        if (chInDate !== chkOutDate && soutput_date === eoutput_date) {
          setgetErrorMessagefunc([`Same date modification not allowed`]);

          setTimeout(() => {
            setgetErrorMessagefunc([]);
            error_close();
          }, 2000);
        } else {
          if (modifydatediff >= 0) {
            if (setstaychangedate?.BookingLevel === "Bed") {
              if (
                chInDate == document.querySelector("#edit_s_date").value &&
                chkOutDate == document.querySelector("#edit_e_date").value &&
                roomdata?.FromRoomId === changeroomid[0]?.RoomId
              ) {
                setget_ErrorMessagefunc([`No changes made by user`]);

                setTimeout(() => {
                  setget_ErrorMessagefunc([]);
                }, 2000);
              } else {
         
                // if (roomdata?.FromRoomId === changeroomid[0]?.RoomId) {
                //if (roomdata?.FromRoomId ===roomdata?.ToRoomId) {




                // iftesting (parseInt(beddata?.FromRoomId) == ( parseInt(diffroomdata?.ToRoomId) !==undefined ? parseInt(diffroomdata?.ToRoomId): parseInt(beddata?.ToRoomId) !== undefined ? parseInt(beddata?.ToRoomId) : edit_modi_check?.RoomId )) {
         
                  if(parseInt(change_event_data?.RoomId) == parseInt(changeroomid[0]?.RoomId) ) {
                    
                    isLoadingfunc(true);
                  setTimeout(() => {
                    modificationinsert({ data: beddata, bodyData: bodyData });
                
                 
                  },1000)
                } else {
                  if (diffroomdata?.ToRoomId !== undefined) {
                  //  modificationshiftinsert({ data: diffroomdata, bodyData: bodyData });
                  isLoadingfunc(true);
                  setTimeout(() => {
                    modificationshiftinsert({ data: diffroomdata, bodyData: bodyData });
                   
                  },1000)
                    
                  } else {
                    show_error_templatefunc(true);
                    getErrorMessagefunc([`Room not available`]);
                   
                    let s_date = document.querySelector("#STime")?.value.split("/");
                    let e_date = document.querySelector("#ETime")?.value.split("/");

                    let bodyData1 = {
                      PropertyId: property_details[0]?.Id || propertyID[0],
                      FromDte: s_date[1] + "/" + s_date[0] + "/" + s_date[2],
                      ToDte: e_date[1] + "/" + e_date[0] + "/" + e_date[2],
                      PropertyName: property_details[0]?.Property || document.querySelector("#ghproperty")?.value,
                    };

                    gridoccupencydatafunc(bodyData1);

                    setTimeout(() => {
                      show_error_templatefunc(false);
                    }, 2000);
                  }
                }

                showChangedatafunc(false);
              }
           
            } else {
              //roomshifting
              if (
                chInDate == document.querySelector("#edit_s_date").value &&
                chkOutDate == document.querySelector("#edit_e_date").value &&
                roomdata?.FromRoomId == roomdata?.ToRoomId
              ) {
                setget_ErrorMessagefunc([`No changes made by user`]);

                setTimeout(() => {
                  setget_ErrorMessagefunc([]);
                }, 2000);
              } else {
                if (roomdata?.FromRoomId === changeroomid[0]?.RoomId) {
                  modificationinsert({ data: roomdata, bodyData: bodyData });
                  
                } else {
               
                  modificationshiftinsert({ data: diffroomdata, bodyData: bodyData });
                }
              
                showChangedatafunc(false);
              }
            }
          } else {
            setgetErrorMessagefunc([`New check-out date should be greater than new check-in date`]);
            seterr_msg("Error");
            setTimeout(() => {
              setgetErrorMessagefunc([]);
              error_close();
            }, 2000);
          }
        }

        // }
        // else {
        //   setgetErrorMessagefunc([]);
        //   setgetErrorMessagefunc([`Room not available`]);
        //   setTimeout(()=> { setgetErrorMessagefunc([]);error_close();},2000)
        // }
      } else {
        //show_error_templatefunc(true);
        setgetErrorMessagefunc([]);
        setgetErrorMessagefunc([`Please enter modification remarks`]);
        setTimeout(() => {
          setgetErrorMessagefunc([]);
          error_close();
        }, 2000);
        errorClassNamefunc("error_warning6");
      }
    }
  };
  const onTimeChange = (args) => {
    // settime_changefunc(args.value);
    // //can remove
    // timecheck({
    //   intime: timeformatcheck(document.querySelector("#checkintime")?.value),
    //   outtime: timeformatcheck(document.querySelector("#checkouttime")?.value),
    // });
    // setmsg_option("Error");
    // setchangelog(["Changes that you made may not be saved", "do you want to continue?"]);
    // if (document.querySelector("#EventType").value === "Bed") {
    //   checkroomavailbed();
    // } else {
    //   checkroomavail("Room");
    // }
  };

  const modificationinsert = (data) => {
    isLoadingfunc(true);
   
    axios
      .post(`${proxy_Url}/BookingModification`, data?.data, { headers: headers })
      .then((res) => {
        // show_error_templatefunc(true);
     
        set_Error_Message_func([res.data.Result]);
        setTimeout(() => {
          set_Error_Message_func([]);
        }, 1000);
        if(res.data.Result !== '*  Room Not Available') {
        setErrorMsgClassfunc({ class: "error-success", msg: "Success" });
        errorClassNamefunc("error_success");
        }
        else {
          setErrorMsgClassfunc({ class: "error_error_msg", msg: "Error" });
       // errorClassNamefunc("error_success");
        }
        isLoadingfunc(false);
        //clear modification data
        editmodificationfunc([]);
        seterr_msg("Success");

        gridoccupencydatafunc(data?.bodyData);

        setchangelog([]);
        setshowchangelog(false);
        //clear the modify check
        setedit_modi_check([]);
        editmodicheckfunc([]);
      })
      .catch((error) => {
        
        setgetErrorMessagefunc([`Room not available`]);
        set_Error_Message_func([`Room not available`]);
        setTimeout(() => {
          set_Error_Message_func([]);
        }, 1000);
        setTimeout(() => {
          setgetErrorMessagefunc([]);
          error_close();
        }, 2000);
        gridoccupencydatafunc(data.bodyData);
        setErrorMsgClassfunc({ class: "error-error", msg: "Error" });

        isLoadingfunc(false);

        setchangelog([]);
        setshowchangelog(false);
      });
  };
  const modificationshiftinsert = (data) => {
    isLoadingfunc(true);

    axios
      .post(`${proxy_Url}/RoomShifting`, data?.data, { headers: headers })
      .then((res) => {
        let s_date = document.querySelector("#STime")?.value.split("/");
        let e_date = document.querySelector("#ETime")?.value.split("/");
        let gridData = {
          PropertyId: property_details[0]?.PropertyId || property_details[0]?.Id || propertyID[0],
          FromDte: s_date[1] + "/" + s_date[0] + "/" + s_date[2],
          ToDte: e_date[1] + "/" + e_date[0] + "/" + e_date[2],
          PropertyName:
            property_details[0]?.PropertyName ||
            property_details[0]?.Property ||
            document.querySelector("#ghproperty")?.value,
          ss: "3",
        };
        gridoccupencydatafunc(gridData);
        isLoadingfunc(false);
        
        // show_error_
        //clear the modify check

        setgetErrorMessagefunc([`Shifted successfully`]);
        set_Error_Message_func([`Shifted successfully`]);
        setTimeout(() => {
          set_Error_Message_func([]);
          setgetErrorMessagefunc([]);
        }, 1000);
        setErrorMsgClassfunc({ class: "error-success", msg: "Success" });
        setedit_modi_check([]);
        editmodicheckfunc([]);
      })
      .catch((error) => {
        //console.log(error.message);
        isLoadingfunc(false);
      });
  };

  const clearData = () => {};

  const error_close = () => {
    setgetErrorMessagefunc([]);
  };

  //date change
  let chkindate = change_event_data?.CheckInDt?.split("/");
  let chInDate = chkindate[1] + "/" + chkindate[0] + "/" + chkindate[2];
  let chkoutdate = change_event_data?.CheckOutDt?.split("/");
  let chkOutDate = chkoutdate[1] + "/" + chkoutdate[0] + "/" + chkoutdate[2];

  const confirm_changlog = () => {
    setchangelog([]);
    showChangedatafunc(false);
    changeeventdatafunc([]);
    setCancelEvent(false);
    setSchedulerData(scheduledataoriginal[0]);
  };
  const cancel_changlog = () => {
    setshowchangelog(false);
  };
  
  return (
    <>
      {changelog?.length > 0 && showchangelog === true && (
        <div className="error_message staydaysblock">
          <div className="error_Message_content t-center">
            <div className="error_MSg_Con">
              <span>Alert Message</span>{" "}
            </div>
            <div className="error_content t-center">
              {changelog?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
            <button className="btn btn-sm btn-info mt-n1 mb-2 mr-2 continue-but" onClick={confirm_changlog}>
              Yes
            </button>
            <button className="btn btn-sm btn-warning mt-n1 mb-2 mr-2 continue-but" onClick={cancel_changlog}>
              No
            </button>
          </div>
        </div>
      )}
      {getErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span>{err_msg} Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content t-center">
              {getErrorMessage?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {getroomerrormsg?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span>{err_msg} Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content t-center">
              {getroomerrormsg?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {get_ErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span>{err_msg} Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content t-center">
              {get_ErrorMessage?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {e_customFields && <CustomFieldsEdit e_customFields={e_customFields} setE_customFields={setE_customFields} />}
      {ghaccessdata[0]?.ModifyResFlg === true && (
        <form onSubmit={onSubmit}>
          <div className="changeEditor">
            <div className="editorBody">
              <div className="header">
                <span>
                  Booking Modification for <b>{`${ghproperty_Name?.value} | ${setstaychangedate?.ClientName}`}</b>{" "}
                </span>
                <span className="d-flex ml-auto bg-light popup_Close" onClick={changeDatafunc}>
                  X
                </span>
              </div>
              <div className="body">
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-xl-4 booking_input">
                    <div className="col-md-12 my-2">
                      <span>Guest Name</span> : <b>{change_event_data?.Subject}</b>
                    </div>
                    <div className="col-md-12">
                      <span>Status</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :{" "}
                      <b>{change_event_data?.Type}</b>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-4 booking_input">
                    <div className="col-md-12 my-2">
                      <span>Current Check-In Date</span> &nbsp;&nbsp;&nbsp;: <b>{chInDate}</b>
                    </div>
                    <div className="col-md-12">
                      <span>Current Check-Out Date</span> : <b>{chkOutDate}</b>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 col-xl-4 booking_input">
                    <div className="col-md-12 my-2">
                      <span>Booking Confirmation Number</span>&nbsp;:&nbsp;<b>{change_event_data?.BookingCode}</b>
                    </div>
                  </div>
                </div>

                <div className="w-100 p-1 mt-3" style={{ background: "#dde9f8" }}>
                  <div className="">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="change_editor">
                          Modification Remarks{" "}
                          <span className="c-red" style={{ color: "red" }}>
                            *
                          </span>{" "}
                          :{" "}
                          <input
                            type="text"
                            id="remarks"
                            className="w-100 input_box"
                            name="remarks"
                            ref={remarks}
                            onInput={propInput}
                            onChange={() => {
                              setchangelog(["Changes that you made may not be saved", "do you want to close?"]);
                              setmsg_option("Error");
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className={`change_editor ${change_event_data?.Type === "CheckIn" && "blocknewcheckin"}`}>
                          New Check-In Date :{" "}
                          <DatePickerComponent
                            format="dd/MM/yyyy"
                            id="edit_s_date"
                            selected={startDate}
                            // min={new Date(startDate)}
                            ref={(date) => (startmodifyObj = date)}
                            className="form-control date-picker"
                            onChange={(date) => {
                              setStartDatefunc(date?.target?.value);
                              setchangelog(["Changes that you made may not be saved", "do you want to close?"]);
                              setmsg_option("Error");
                              stayshiftfunc("resizeevent");
                              dragChangeData();
                            }}
                            value={new Date(startDate)}
                            onFocus={startonFocusmodify}
                            readOnly={change_event_data?.Type === "CheckIn" && true}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="change_editor">
                          New Check-Out Date :{" "}
                          <DatePickerComponent
                            id="edit_e_date"
                            format="dd/MM/yyyy"
                            ref={(date) => (endmodifyObj = date)}
                            min={
                              GH_property_ClientName[0][1][0]?.CheckIn24Hrs
                                ? new Date(startDate)
                                : new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1))
                            }
                            //selected={endDate}
                            className="form-control date-picker"
                            onChange={(date) => {
                              setEndDate(date?.target?.value);
                              setchangelog(["Changes that you made may not be saved", "do you want to close?"]);
                              setmsg_option("Error");
                              stayshiftfunc("resizeevent");
                              dragChangeData();
                            }}
                            value={new Date(endDate)}
                            onFocus={EndonFocusmodify}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-4 mofidy_timecheck">
                      { change_event_data?.Type === "CheckIn" && change_event_data?.Type === "CheckIn" && <> New Check-In Time :{" "} <TimePickerComponent
                              id="checkintime"
                              openOnFocus={true}
                              onChange={onTimeChange}
                              min={"12:00 PM"}
                              //selected={"12:00 PM"  }
                              ref={(scope) => (intimeobj = scope)}
                              step={60}
                              value={time_change || "12:00 PM"}
                              placeholder="Select a Time"
                              //focus={starttimeFocus}
                            /> </>}
</div> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 my-2">
                    <input
                      type="button"
                      value="Custom Fields"
                      style={{ float: "right" }}
                      className="btn btn-danger btn-sm"
                      onClick={editCustomField}
                      disabled={edit_cus_data && edit_cus_data[2][0]?.Column1 && true}
                    />
                  </div>
                </div>
              </div>
              <div className="footer">
                <input
                  type="button"
                  value="Check Availability & Modify"
                  className="btn btn-success btn-sm mr-1"
                  onClick={checkModify}
                />
                {/* <input type="button" value="Clear" className="btn btn-danger btn-sm" onClick={clearData} /> */}
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default React.memo(ChangeEventTemplate);
