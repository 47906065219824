import React, { useState, useContext } from "react";
import LogoLight2x from "../../images/hb/HB_logo.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import { FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { BookingContent } from "../../rcomponents/bookingContext/BookingContext";

const ForgotPassword = () => {
  const { proxy_Url } = useContext(BookingContent);

  const [forgotUName, setForgotUName] = useState("");

  const sendReset = (ev) => {
    ev.preventDefault();

    //forgot password
    let bodyData = {
      Email: `${forgotUName}`,
    };

    axios
      .post(`${proxy_Url}/forgetpwd`, bodyData)
      .then((res) => {
        let Rdata = res.data.Result;
      })
      .catch((err) => 
      {
        // console.log(err.message)
      });
  };

  const onPaste = (e) => {
    e.clipboardData.getData("frogotemail");
  };
  return (
    <React.Fragment>
      <Head title="Forgot-Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body wide-xs">
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <div className="brand-logo pb-4 text-center w-100 d-flex align-item-center justify-content-center">
                  {/* <Link to={process.env.PUBLIC_URL + "/"} className="logo-link"> */}
                  <img
                    className="logo-dark logo-img logo-img-lg no-bg"
                    style={{ background: "none !important" }}
                    src="https://endpoint887127.azureedge.net/pimages/OrTFH2wg_newhblogo_250x150.png"
                    alt="logo-dark"
                  />
                  {/* </Link> */}
                </div>
                <BlockTitle tag="h5">Reset password</BlockTitle>
                <BlockDes>
                  <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <form>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="default-01"
                  placeholder="Enter your email address"
                  defaultValue={forgotUName}
                  name="frogotemail"
                  onChange={(e) => setForgotUName(e.target.value)}
                  onPaste={onPaste}
                />
              </FormGroup>
              <FormGroup>
                <Button color="primary" size="lg" className="btn-block" onClick={sendReset}>
                  Send Reset Link
                </Button>
              </FormGroup>
            </form>
            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Return to login</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;
