import React, { useCallback, useEffect, useState, useContext, useRef } from "react";
import { BookingContent } from "../bookingContext/BookingContext";
//import axios from "axios";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";

const NewBookingCustomFields = ({
  setShow_Tr_count,
  new_C_Fields,
  setNew_C_Fields,
  gettr_fromstorage,
  getAdditionalValue,
  add_userenteryGuestDetails,
  ClearBooking,
  ClearDataFields,
  //setSelectedValue
}) => {
  const { new_booking_data, clientcustomfieldscollection, propInput } = useContext(BookingContent);
  let field1 = useRef();
  let field2 = useRef();
  let field3 = useRef();
  let field4 = useRef();
  let field5 = useRef();
  let field6 = useRef();
  let field7 = useRef();
  let field8 = useRef();
  let field9 = useRef();
  let field10 = useRef();
  const [getErrorMessage, setgetErrorMessagefunc] = useState([]);
  // const [changelog, setchangelog] = useState([]);
  // const [showchangelog, setshowchangelog] = useState(false);
  const [editFormData, setEditFormData] = useState({
    Column1: "",
    Column2: "",
    Column3: "",
    Column4: "",
    Column5: "",
    Column6: "",
    Column7: "",
    Column8: "",
    Column9: "",
    Column10: "",
  });

  const closeFields = useCallback(() => {
    // if (changelog?.length > 0) {
    //   setshowchangelog(true);
    // } else {
    setNew_C_Fields(!new_C_Fields);
    setEditFormData({});
    //ClearBooking();
    // ClearDataFields();
    // setshowchangelog(false);
    // setchangelog([]);
    // }
  }, []);

  // const confirm_changlog = () => {
  //   setNew_C_Fields(!new_C_Fields);
  //   setEditFormData({})
  //   ClearDataFields();
  //   setshowchangelog(false);
  //   setchangelog([]);
  //   setshowchangelog(true);
  // };
  // const cancel_changlog = () => {
  //   setshowchangelog(false);

  // };

  useEffect(() => {
    setTimeout(() => {
      setEditFormData((prev) => ({
        Column1: gettr_fromstorage?.Column1,
        Column2: gettr_fromstorage?.Column2,
        Column3: gettr_fromstorage?.Column3,
        Column4: gettr_fromstorage?.Column4,
        Column5: gettr_fromstorage?.Column5,
        Column6: gettr_fromstorage?.Column6,
        Column7: gettr_fromstorage?.Column7,
        Column8: gettr_fromstorage?.Column8,
        Column9: gettr_fromstorage?.Column9,
        Column10: gettr_fromstorage?.Column10,
      }));
    }, 300);
  }, [gettr_fromstorage]);
  const handlesubmit = (e) => {
    e.preventDefault();
  };
  //onChange event
  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setEditFormData((prev) => ({ ...prev, [name]: value }));
    //  setchangelog(["Changes that you made may not be saved, do you want to close?"]);
  };

  let edit_cus_data = new_booking_data;

  const EditcustomFields = () => {
    setgetErrorMessagefunc([]);
    let col1 = document.querySelector("#c_fields_1")?.value;
    let col2 = document.querySelector("#c_fields_2")?.value;
    let col3 = document.querySelector("#c_fields_3")?.value;
    let col4 = document.querySelector("#c_fields_4")?.value;
    let col5 = document.querySelector("#c_fields_5")?.value;
    let col6 = document.querySelector("#c_fields_6")?.value;
    let col7 = document.querySelector("#c_fields_7")?.value;
    let col8 = document.querySelector("#c_fields_8")?.value;
    let col9 = document.querySelector("#c_fields_9")?.value;
    let col10 = document.querySelector("#c_fields_10")?.value;

    if (
      (col1 === "" && edit_cus_data[2][0]?.Column1Mandatory === true) ||
      (col2 === "" && edit_cus_data[2][0]?.Column2Mandatory === true) ||
      (col3 === "" && edit_cus_data[2][0]?.Column3Mandatory === true) ||
      (col4 === "" && edit_cus_data[2][0]?.Column4Mandatory === true) ||
      (col5 === "" && edit_cus_data[2][0]?.Column5Mandatory === true) ||
      (col6 === "" && edit_cus_data[2][0]?.Column6Mandatory === true) ||
      (col7 === "" && edit_cus_data[2][0]?.Column7Mandatory === true) ||
      (col8 === "" && edit_cus_data[2][0]?.Column8Mandatory === true) ||
      (col9 === "" && edit_cus_data[2][0]?.Column9Mandatory === true) ||
      (col10 === "" && edit_cus_data[2][0]?.Column10Mandatory === true)
    ) {
      setgetErrorMessagefunc([
        col1 === "" && edit_cus_data[2][0]?.Column1Mandatory === true && `${edit_cus_data[2][0]?.Column1} is required`,
        col2 === "" && edit_cus_data[2][0]?.Column2Mandatory === true && `${edit_cus_data[2][0]?.Column2} is required`,
        col3 === "" && edit_cus_data[2][0]?.Column3Mandatory === true && `${edit_cus_data[2][0]?.Column3}  is required`,
        col4 === "" && edit_cus_data[2][0]?.Column4Mandatory === true && `${edit_cus_data[2][0]?.Column4}  is required`,
        col5 === "" && edit_cus_data[2][0]?.Column5Mandatory === true && `${edit_cus_data[2][0]?.Column5}  is required`,
        col6 === "" && edit_cus_data[2][0]?.Column6Mandatory === true && `${edit_cus_data[2][0]?.Column6}  is required`,
        col7 === "" && edit_cus_data[2][0]?.Column7Mandatory === true && `${edit_cus_data[2][0]?.Column7}  is required`,
        col8 === "" && edit_cus_data[2][0]?.Column8Mandatory === true && `${edit_cus_data[2][0]?.Column8}  is required`,
        col9 === "" && edit_cus_data[2][0]?.Column9Mandatory === true && `${edit_cus_data[2][0]?.Column9}  is required`,
        col10 === "" &&
          edit_cus_data[2][0]?.Column10Mandatory === true &&
          `${edit_cus_data[2][0]?.Column10}  is required`,
      ]);
      setTimeout(() => {
        setgetErrorMessagefunc([]);
      }, 2000);
    } else {


      let c_fields_1 = document.querySelector("#c_fields_1")?.value;
      let c_fields_2 = document.querySelector("#c_fields_2")?.value;
      let c_fields_3 = document.querySelector("#c_fields_3")?.value;
      let c_fields_4 = document.querySelector("#c_fields_4")?.value;
      let c_fields_5 = document.querySelector("#c_fields_5")?.value;
      let c_fields_6 = document.querySelector("#c_fields_6")?.value;
      let c_fields_7 = document.querySelector("#c_fields_7")?.value;
      let c_fields_8 = document.querySelector("#c_fields_8")?.value;
      let c_fields_9 = document.querySelector("#c_fields_9")?.value;
      let c_fields_10 = document.querySelector("#c_fields_10")?.value;
      let edit_form_data = {
        // Column1: editFormData?.Column1 !== undefined ? editFormData?.Column1 : "",
        // Column2: editFormData?.Column2 !== undefined ? editFormData?.Column2 : "",
        // Column3: editFormData?.Column3 !== undefined ? editFormData?.Column3 : "",
        // Column4: editFormData?.Column4 !== undefined ? editFormData?.Column4 : "",
        // Column5: editFormData?.Column5 !== undefined ? editFormData?.Column5 : "",
        // Column6: editFormData?.Column6 !== undefined ? editFormData?.Column6 : "",
        // Column7: editFormData?.Column7 !== undefined ? editFormData?.Column7 : "",
        // Column8: editFormData?.Column8 !== undefined ? editFormData?.Column8 : "",
        // Column9: editFormData?.Column9 !== undefined ? editFormData?.Column9 : "",
        // Column10: editFormData?.Column10 !== undefined ? editFormData?.Column10 : "",
        Column1: c_fields_1 !== undefined ? c_fields_1 : '',
        Column2: c_fields_2 !== undefined ? c_fields_2 : '',
        Column3: c_fields_3 !== undefined ? c_fields_3 : '',
        Column4: c_fields_4 !== undefined ? c_fields_4 : '',
        Column5: c_fields_5 !== undefined ? c_fields_5 : '',
        Column6: c_fields_6 !== undefined ? c_fields_6 : '',
        Column7: c_fields_7 !== undefined ? c_fields_7 : '',
        Column8: c_fields_8 !== undefined ? c_fields_8 : '',
        Column9: c_fields_9 !== undefined ? c_fields_9 : '',
        Column10: c_fields_10 !== undefined ? c_fields_10 : '',
      };
       getAdditionalValue(edit_form_data);
       setNew_C_Fields(!new_C_Fields);
       add_userenteryGuestDetails(edit_form_data);
     
       setShow_Tr_count(1);
    }
    //("Select")
  };

  const error_close = () => {
    setgetErrorMessagefunc([]);
  };
  // custom fields array
  let column_data;
  if (clientcustomfieldscollection !== "No records found") {
    column_data = clientcustomfieldscollection?.map((item) => item?.map((listitem) => listitem?.label));
  }
  return (
    <>
      {/* {changelog?.length > 0 && showchangelog === true && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span>Error Message</span>{" "}
            </div>
            <div className="error_content t-center">
              {changelog?.map((item, i) => (
                <p className="t-center" key={i}>{item && ` ${item}`}</p>
              ))}
            </div>
            <button className="btn btn-sm btn-warning float-right mt-n1 mb-2 mr-2" onClick={cancel_changlog}>
              Cancel
            </button>

            <button className="btn btn-sm btn-info float-right mt-n1 mb-2 mr-2" onClick={confirm_changlog}>
              Close
            </button>
          </div>
        </div>
      )} */}
      {getErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span> Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content t-center">
              {getErrorMessage?.map((item, i) => (
                <p className="t-center" key={i}>
                  {" "}
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="addFieldsbg" /*style={{display: addifields ? 'flex': 'none'}}*/>
        <div className="AddFieldsBlock">
          <div className="AddFields">
            <div className="AddFieldsheader d-flex">
              <span>Custom fields</span>{" "}
              <span className="d-flex ml-auto bg-light popup_Close" onClick={closeFields}>
                X
              </span>{" "}
            </div>

            <div className="AddFieldsbody customentry">
              <form onSubmit={handlesubmit}>
                <table className="table" style={{ marginBottom: "0px !important" }}>
                  <tbody>
                    {edit_cus_data[2][0]?.Column1 ? (
                      <tr>
                        <td style={{ borderTop: "0px" }}>
                          {edit_cus_data[2][0]?.Column1}
                          {edit_cus_data[2][0]?.Column1Mandatory === true && <span className="text-danger"> *</span>}
                        </td>
                        <td style={{ borderTop: "0px" }}>
                          <AutoCompleteComponent
                            id="c_fields_1"
                            name="Column1"
                            value={editFormData.Column1}
                            placeholder="Enter value"
                            className="form-control"
                            onChange={handleChange}
                            ref={edit_cus_data[2][0]?.Column1Mandatory === true ? field1 : null}
                            dataSource={clientcustomfieldscollection !== "No records found" ? column_data[0] : []}
                            onInput={propInput}
                            filtering={handleChange}
                            // onfilter={filter}
                          />
                        </td>
                      </tr>
                    ) : null}
                    {edit_cus_data[2][0]?.Column2 ? (
                      <tr>
                        <td>
                          {edit_cus_data[2][0]?.Column2}
                          <span className="text-danger">{edit_cus_data[2][0]?.Column2Mandatory && " *"}</span>
                        </td>
                        <td>
                          <AutoCompleteComponent
                            id="c_fields_2"
                            name="Column2"
                            value={editFormData.Column2}
                            placeholder="Enter value"
                            className="form-control"
                            onChange={handleChange}
                            ref={edit_cus_data[2][0]?.Column2Mandatory === true ? field2 : null}
                            dataSource={clientcustomfieldscollection !== "No records found" ? column_data[1] : []}
                            onInput={propInput}
                          />
                        </td>
                      </tr>
                    ) : null}
                    {edit_cus_data[2][0]?.Column3 ? (
                      <tr>
                        <td>
                          {edit_cus_data[2][0]?.Column3}
                          <span className="text-danger">{edit_cus_data[2][0]?.Column3Mandatory && " *"}</span>
                        </td>
                        <td>
                          <AutoCompleteComponent
                            id="c_fields_3"
                            name="Column3"
                            value={editFormData.Column3}
                            placeholder="Enter value"
                            className="form-control"
                            onChange={handleChange}
                            ref={edit_cus_data[2][0]?.Column3Mandatory === true ? field3 : null}
                            dataSource={clientcustomfieldscollection !== "No records found" ? column_data[2] : []}
                            onInput={propInput}
                          />
                        </td>
                      </tr>
                    ) : null}
                    {edit_cus_data[2][0]?.Column4 ? (
                      <tr>
                        <td>
                          {edit_cus_data[2][0]?.Column4}
                          <span className="text-danger">{edit_cus_data[2][0]?.Column4Mandatory && " *"}</span>
                        </td>
                        <td>
                          <AutoCompleteComponent
                            id="c_fields_4"
                            name="Column4"
                            value={editFormData.Column4}
                            placeholder="Enter value"
                            className="form-control"
                            onChange={handleChange}
                            ref={edit_cus_data[2][0]?.Column4Mandatory === true ? field4 : null}
                            dataSource={clientcustomfieldscollection !== "No records found" ? column_data[3] : []}
                            onInput={propInput}
                          />
                        </td>
                      </tr>
                    ) : null}
                    {edit_cus_data[2][0]?.Column5 ? (
                      <tr>
                        <td>
                          {edit_cus_data[2][0]?.Column5}
                          <span className="text-danger">{edit_cus_data[2][0]?.Column5Mandatory && " *"}</span>
                        </td>
                        <td>
                          <AutoCompleteComponent
                            id="c_fields_5"
                            name="Column5"
                            value={editFormData.Column5}
                            placeholder="Enter value"
                            className="form-control"
                            onChange={handleChange}
                            ref={edit_cus_data[2][0]?.Column5Mandatory === true ? field5 : null}
                            dataSource={clientcustomfieldscollection !== "No records found" ? column_data[4] : []}
                            onInput={propInput}
                          />
                        </td>
                      </tr>
                    ) : null}
                    {edit_cus_data[2][0]?.Column6 ? (
                      <tr>
                        <td>
                          {edit_cus_data[2][0]?.Column6}
                          <span className="text-danger">{edit_cus_data[2][0]?.Column6Mandatory && " *"}</span>
                        </td>
                        <td>
                          <AutoCompleteComponent
                            id="c_fields_6"
                            name="Column6"
                            value={editFormData.Column6}
                            placeholder="Enter value"
                            className="form-control"
                            onChange={handleChange}
                            ref={edit_cus_data[2][0]?.Column6Mandatory === true ? field6 : null}
                            dataSource={clientcustomfieldscollection !== "No records found" ? column_data[5] : []}
                            onInput={propInput}
                          />
                        </td>
                      </tr>
                    ) : null}
                    {edit_cus_data[2][0]?.Column7 ? (
                      <tr>
                        <td>
                          {edit_cus_data[2][0]?.Column7}
                          <span className="text-danger">{edit_cus_data[2][0]?.Column7Mandatory && " *"}</span>
                        </td>
                        <td>
                          <AutoCompleteComponent
                            id="c_fields_7"
                            name="Column7"
                            value={editFormData.Column7}
                            placeholder="Enter value"
                            className="form-control"
                            onChange={handleChange}
                            ref={edit_cus_data[2][0]?.Column7Mandatory === true ? field7 : null}
                            dataSource={clientcustomfieldscollection !== "No records found" ? column_data[6] : []}
                            onInput={propInput}
                          />
                        </td>
                      </tr>
                    ) : null}
                    {edit_cus_data[2][0]?.Column8 ? (
                      <tr>
                        <td>
                          {edit_cus_data[2][0]?.Column8}
                          <span className="text-danger">{edit_cus_data[2][0]?.Column8Mandatory && " *"}</span>
                        </td>
                        <td>
                          <AutoCompleteComponent
                            id="c_fields_8"
                            name="Column8"
                            value={editFormData.Column8}
                            placeholder="Enter value"
                            className="form-control"
                            onChange={handleChange}
                            ref={edit_cus_data[2][0]?.Column8Mandatory === true ? field8 : null}
                            dataSource={clientcustomfieldscollection !== "No records found" ? column_data[7] : []}
                            onInput={propInput}
                          />
                        </td>
                      </tr>
                    ) : null}
                    {edit_cus_data[2][0]?.Column9 ? (
                      <tr>
                        <td>
                          {edit_cus_data[2][0]?.Column9}
                          <span className="text-danger">{edit_cus_data[2][0]?.Column9Mandatory && " *"}</span>
                        </td>
                        <td>
                          <AutoCompleteComponent
                            id="c_fields_9"
                            name="Column9"
                            value={editFormData.Column9}
                            placeholder="Enter value"
                            className="form-control"
                            onChange={handleChange}
                            ref={edit_cus_data[2][0]?.Column9Mandatory === true ? field9 : null}
                            dataSource={clientcustomfieldscollection !== "No records found" ? column_data[8] : []}
                            onInput={propInput}
                          />
                        </td>
                      </tr>
                    ) : null}
                    {edit_cus_data[2][0]?.Column10 ? (
                      <tr>
                        <td>
                          {edit_cus_data[2][0]?.Column10}
                          <span className="text-danger">{edit_cus_data[2][0]?.Column10Mandatory && " *"}</span>
                        </td>
                        <td>
                          <AutoCompleteComponent
                            id="c_fields_10"
                            name="Column10"
                            value={editFormData.Column10}
                            placeholder="Enter value"
                            className="form-control"
                            onChange={handleChange}
                            ref={edit_cus_data[2][0]?.Column10Mandatory === true ? field10 : null}
                            dataSource={clientcustomfieldscollection !== "No records found" ? column_data[9] : []}
                            onInput={propInput}
                          />
                        </td>
                      </tr>
                    ) : null}

                    <tr>
                      <td colSpan="2">
                        <div className="AddFieldsfooter">
                          <button className="btn btn-primary btn-sm" onClick={EditcustomFields}>
                            Add
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(NewBookingCustomFields);
