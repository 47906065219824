import React from "react";
import {Link} from "react-router-dom";

const Logo = () => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
      <img className="logo-light logo-img" style={{background:'none'}} src='https://endpoint887127.azureedge.net/pimages/OrTFH2wg_newhblogo_250x150.png' alt="logo" />
    </Link>
  );
};

export default Logo;
